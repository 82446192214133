import React, { useContext, useState } from "react";
import AppContext from "../../context/store";
import styled from "styled-components";

import { Grid, Column } from "../../theme/Grid";
import Button from "../../theme/Button";

const CustomDateRangeColumn = (props) => {
  const context = useContext(AppContext);
  const [selectedRange, setSelectedRange] = useState(0);

  return (
    <Column
      className={props.columnClassName ? props.columnClassName : null}
      style={{ position: "relative", ...props.style }}
    >
      <SectionTitle>
        {props.sectionTitle ? props.sectionTitle : null}
      </SectionTitle>
      <Grid className="no-gutters-all mb-1">
        <Column className="xs-4 sm-4">
          <Button
            title={context.t(`component.dateRangeStats.today`)}
            fullWidth={true}
            textColor={selectedRange === 0 ? "primary" : "grey"}
            backgroundColor={selectedRange === 0 ? "primary-opacity" : null}
            onClick={
              selectedRange !== 0
                ? () => {
                  setSelectedRange(0);
                  props.onClickHandler(1);
                }
                : null
            }
          />
        </Column>

        <Column className="xs-4 sm-4">
          <Button
            title={context.t(`component.dateRangeStats.lastWeek`)}
            fullWidth={true}
            textColor={selectedRange === 1 ? "primary" : "grey"}
            backgroundColor={selectedRange === 1 ? "primary-opacity" : null}
            onClick={
              selectedRange !== 1
                ? () => {
                  setSelectedRange(1);
                  props.onClickHandler(7);
                }
                : null
            }
          />
        </Column>

        <Column className="xs-4 sm-4">
          <Button
            title={context.t(`component.dateRangeStats.lastMonth`)}
            fullWidth={true}
            textColor={selectedRange === 2 ? "primary" : "grey"}
            backgroundColor={selectedRange === 2 ? "primary-opacity" : null}
            onClick={
              selectedRange !== 2
                ? () => {
                  setSelectedRange(2);
                  props.onClickHandler(30);
                }
                : null
            }
          />
        </Column>
      </Grid>

      {props.children}
    </Column>
  );
};

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 4px;
  color: var(--dark-primary);
`;

export default CustomDateRangeColumn;
