/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import AppointmentCalendar from "../../../functions/NewAppointmentCalendar";
import styled from "styled-components";
import moment from "moment";
import "moment-timezone";
import Axios from "axios";
import {
  IconButton,
  Tooltip,
  Typography,
  MenuItem,
  FormControl,
  OutlinedInput,
  Checkbox,
} from "@material-ui/core";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Button from "../../../theme/Button";
import {
  AccessAlarm,
  Person,
  EventAvailable,
  FilterList,
  Description,
  ChevronRight,
  ChevronLeft,
  InfoOutlined,
  Close,
  Check,
} from "@material-ui/icons";
import { Select as SampleMuiSelect } from "@material-ui/core";
import _ from "lodash";
import NewAppointmentDialog from "../../../components/Dialogs/NewAppointmentDialog";
import OnlineAppointmentRequestDailog from "../../../components/Dialogs/OnlineAppointmentRequestDialog";
import FilterAppointment from "../../../components/Dialogs/FilterAppointment";
import MonthAppointment from "./MonthAppointment";
import WeekAppointment from "./WeekAppointment";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import HomeChcek from "../../../assets/images/svg/home-check.svg";
import Approved from "../../../assets/images/svg/approved.svg";
import NotCome from "../../../assets/images/svg/not-come.svg";
import Waiting from "../../../assets/images/svg/waiting.svg";
import Process from "../../../assets/images/svg/process.svg";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import YouTube from "react-youtube";
import { IoMdClose } from "react-icons/io";
import youtube_image from "../../../assets/images/youtube_image.jpg";
import { useHistory } from "react-router-dom";
import AppointmentCardDetail from "../../../components/Dialogs/AppointmentCardDetail";
import closedTimeCutomer from "../../../assets/images/colsedTimeCustomer.png";
import AddAppointmentDialog from "../../../components/Dialogs/AddAppointmentDialog";
import RefreshIcon from "@material-ui/icons/Refresh";
import { orange } from "@material-ui/core/colors";

const app_name = process.env.APP_NAME;
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#3189ec",
        fontSize: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

const themeForCardDetail = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        zIndex: "999999999",
        width: "250px",
        backgroundColor: "transparent",
      },
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: { zIndex: 1501 },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
      position: "static",
      marginLeft: "90px",
      marginTop: "165px",
    },
  },
};
const selectedStaffCount = localStorage.getItem("table_count")
  ? parseInt(localStorage.getItem("table_count"))
  : 3;

const todayDate = new Date();

const opts = {
  height: "330",
  width: "420",
};

const AppointmentsTableIndex = (props) => {
  const [loadSettings, setLoadSettings] = useState(false);
  const redLine = useRef(null);
  const context = useContext(AppContext);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [infoMessageSeen, setInfoMessageSeen] = useState(false);
  const [hours, setHours] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [noUpdatedStaffs, setNoUpdatedStaffs] = useState([]);
  const [staffSelectDrop, setStaffSelectDrop] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(null);
  const [selectedStaffArr, setSelectedStaffArr] = useState([]);
  const [allStaffSelected, setAllStaffSelected] = useState(
    localStorage.getItem("all_staff") === "true"
  );
  const [selectedStaffValues, setSelectedStaffValues] = useState([]);
  const [widthAppointmentDivider, setWidthAppointmentDivider] = useState(null);
  const [selectedStaffCountWidth, setSelectedStaffCountWidth] = useState([
    {
      selectedWidth_id: 3,
      name: "calc(33.3vw - 46.3px)",
    },
    {
      selectedWidth_id: 5,
      name: "calc(20vw - 29.4px)",
    },
    {
      selectedWidth_id: 7,
      name: "calc(14.28vw - 21.75px)",
    },
    {
      selectedWidth_id: 9,
      name: "calc(11.2vw - 18.75px)",
    },
  ]);
  const [widthStaffBox, setWidthStaffBox] = useState(null);
  const [timeUpgrade, setTimeUpgrade] = useState(null);

  const [recurenctModal, setRecurenctModal] = useState(false);
  const [contextHourDetail, setContextHourDetail] = useState(
    {
      start_hour: context.state.company_shift.start_hour,
      end_hour: context.state.company_shift.end_hour,
    }
  )
  const [hourDetail, setHourDetail] = useState({
    start_hour: context.state.company_shift.start_hour,
    end_hour: context.state.company_shift.end_hour,
  });

  const [nowIndicator, setNowIndicator] = useState(
    `${AppointmentCalendar(
      null,
      contextHourDetail,
      null
    ).getNowIndicatorTopMargin()}%`
  );
  const [_appointment, set_Appointment] = useState(null);
  const [createAppointmentModal, setCreateAppointmentModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(
    props.location.state
      ? moment(props.location.state.date)
      : props.location.search
        ? new Date(props.location.search.split("=")[1])
        : localStorage.getItem("selected_date")
          ? localStorage.getItem("selected_date")
          : new Date()
  );
  const [selectedService, setSelectedService] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeClockOffStart, setSelectedTimeClockOffStart] =
    useState(null);
  const [selectedTimeClockOffEnd, setSelectedTimeClockOffEnd] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [clockOff, setClockOff] = useState([]);
  const [selectedClock, setSelectedClock] = useState(null);

  const [changeWeek, setChangeWeek] = useState({
    start_date: moment(new Date()).startOf("isoWeek"),
    end_date: moment(new Date()).endOf("isoWeek"),
  });
  const [dragDropDeleteAppointmentId, setDragDropDeleteAppointmentId] =
    useState(null);
  const [openUpdateAppointmentDialog, setOpenUpdateAppointmentDialog] =
    useState(false);
  const [selectedFilterPicker, setSelectedFilterPicker] = useState(null);
  const [tableLargeRow, setTableLargeRow] = useState(false);
  const [monthlyAppointments, setMonthlyAppointments] = useState(false);
  const [weeklyAppointments, setWeeklyAppointments] = useState(false);
  const [weeklyAppointmentsSelectedDate, setWeeklyAppointmentsSelectedDate] =
    useState(null);
  const [openNewAppointmentDialog, setOpenNewAppointmentDialog] =
    useState(false);
  const [
    openOnlineAppointmentRequestDialog,
    setOpenOnlineAppointmentRequestDialog,
  ] = useState(false);
  const [openFilterAppointmentDialog, setOpenFilterAppointmentDialog] =
    useState(false);
  const [updatingApp, setUpdatingApp] = useState(false);
  const [youtubePopUp, setYoutubePopUp] = useState(false);

  const [appointmentDetailModalClock, setAppointmentDetailModalClock] =
    useState(false);
  const [appointmentDetailModal, setAppointmentDetailModal] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [shortCode, setShortCode] = useState(null);
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClickRefreshButton = () => {
    setLoaded(false);
    getAppointmentClockTime();
    getAppointmentsForSelectBox();
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000); // Disable for 2 seconds
  };

  // get shortcode
  const getShortcode = async () => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/shortcode/list/keyword`
      );
      if (response.status === 200) {
        setShortCode(response.data.data.keyword);
      }
    } catch (error) {
      console.error("Error fetching shortcode:", error);
    }
  };
  const getTemplates = async () => {
    try {
      const { data } = await Axios.get(
        `${context.api_endpoint}/company/sms/template/all`
      );
      setSmsTemplates([...data.data]);
    } catch (error) {
      console.error("Error fetching SMS templates:", error);
    }
  };

  const getCompanyHours = async () => {
    try {
      const { data } = await Axios.get(`${context.api_endpoint}/company/hours`);
      setContextHourDetail({
        IsSet: data.data.IsSet,
        end_hour: data.data.end_hour,
        id: data.data.id,
        slice: data.data.slice,
        start_hour: data.data.start_hour,
      });
      const generatedHours = BetweenTimeGenerator({
        IsSet: data.data.IsSet,
        end_hour: data.data.end_hour,
        id: data.data.id,
        slice: data.data.slice,
        start_hour: data.data.start_hour,
      });
      setHours(generatedHours);
    } catch (error) {
      if (error.response && error.response.data.code === 401) {
        history.push("/wallet");
      }
      // Handle other errors
      console.error("Error fetching company hours:", error);
    }
  };

  const getAppointmentsByWeek = () => {
    // 7 times same staffs for weekly appointments
    setTableLargeRow(false);
    setWeeklyAppointments(true);
    setMonthlyAppointments(false);
  };

  const getAppointmentsByMonth = () => {
    setWeeklyAppointments(false);
    setMonthlyAppointments(true);
  };

  const getAppointmentsByDate = async () => {
    try {
      const { data } = await Axios.post(
        `${context.api_endpoint}/company/appointments`,
        {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          staff_id: [],
        }
      );
      if (data.data?.length !== 0) {
        setStaffs([...data.data]);
        setNoUpdatedStaffs([...data.data]);
        setLoaded(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  const getAppointmentsForSelectBox = async () => {
    try {
      const { data } = await Axios.post(
        `${context.api_endpoint}/company/appointments`,
        {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          staff_id: selectedStaffArr.filter((id) => id > 0) || [],
        }
      );
      if (data.data?.length !== 0) {
        setStaffs([...data.data]);
        setLoaded(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  // get staff for select box
  const getStaffForSelectBox = async () => {
    try {
      const { data } = await Axios.post(
        `${context.api_endpoint}/company/appointments`,
        {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          staff_id: [],
        }
      );
      if (data.data.length !== 0) {
        setNoUpdatedStaffs([...data.data]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  // TODO: Get Close Appointment Clock Time
  const getAppointmentClockTime = async () => {
    const appointment_date = moment(selectedDate).format("YYYY-MM-DD");
    try {
      const { data } = await Axios.get(
        `${context.api_endpoint}/company/appointment/staff/active/date`,
        {
          params: {
            date: `${appointment_date}T00:00:00.000Z`,
            choice: null,
          },
        }
      );
      if (data.data?.length > 0) {
        setClockOff([...data.data[0].staff_availability_per_day]);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  // TODO: Close Appointment Delete
  const deleteAppointmentClockTime = async (clock_id, recurrent) => {
    try {
      const { data } = await Axios.delete(
        `${context.api_endpoint}/company/appointment/staff/active/date/${clock_id}`,
        {
          params: {
            is_recurrent: recurrent === 1 ? 1 : null,
          },
        }
      );
      if (data.status === 200) {
        toast.success(data.data);
        setAppointmentDetailModalClock(false);
        getAppointmentClockTime();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    } finally {
      setCreateAppointmentModal(false);
    }
  };

  const updateAppointment = async () => {
    let appointment_date = new Date(selectedDate);
    let appointment_time = selectedTime.split(":");
    if (appointment_time[0] > 6) {
      appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${appointment_time[0]
        }:${appointment_time[1]}:00Z`;
    } else {
      appointment_date = `${moment(appointment_date)
        .add(1, "day")
        .format("YYYY-MM-DD")}T${appointment_time[0]}:${appointment_time[1]
        }:00Z`;
    }
    if (!updatingApp) {
      await Axios.post(`${context.api_endpoint}/company/appointment/transfer`, {
        id: dragDropDeleteAppointmentId,
        staff_id: selectedStaff.id,
        appointment_date,
        send_sms: true,
      })
        .then((response) => {
          toast.success(
            context.t(
              `appointments.updateAppointment.updateAppointmentSuccessToast`
            )
          );
          getAppointmentsForSelectBox();
          setUpdatingApp(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
  };

  const updateAppointmentState = async (appointmentId, customerState) => {
    await Axios.put(
      `${context.api_endpoint}/company/appointment/customer/state`,
      {
        appointment_id: appointmentId,
        customer_state: customerState,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          getAppointmentsForSelectBox();
          toast.success(
            context.t(
              `appointments.detail.appointmentStatus.changeStatusSuccessToast`
            )
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getDividerAndSelectedStaff = async () => {
    await Axios.get(`${context.api_endpoint}/staff/favorites`).then(
      (response) => {
        if (response.status === 200) {
          setWidthAppointmentDivider(response.data.data.divider);
          setSelectedStaffArr(
            response.data.data.selected_staffs
              .split(",")
              .map((item) => parseInt(item))
          );
        }
      }
    );
  };

  const redLineToCenter = () => {
    setTimeout(() => {
      if (redLine.current) {
        redLine.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 600);
  };

  const handleDragStart = (e, appointment) => {
    e.dataTransfer.setData("appointment", JSON.stringify(appointment));
  };

  const handleDragCapture = (e) => {
    e.currentTarget.style.opacity = "0.7";
    e.currentTarget.style.border = "1px solid black";
    e.currentTarget.style.background = "white";
    e.currentTarget.style.color = "black";
  };
  const handleDragEnd = (e, appointment) => {
    const { currentTarget } = e;
    currentTarget.style.opacity = "1";
    currentTarget.style.border = "none";
    currentTarget.style.background =
      AppointmentCalendar(appointment).getCardBackgroundColor();
    currentTarget.style.color =
      AppointmentCalendar(appointment).getCardTextColor();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.style.border = "2px solid gray";
  };

  const handleDragLeave = (e) => {
    e.target.style.border = "none";
  };

  const handleDropCapture = (e) => {
    e.target.style.border = "none";
  };

  const handleDrop = async (e, hour, staff) => {
    e.preventDefault();
    const { currentTarget } = e;

    const scElement = currentTarget.querySelector(".sc-iWBNLc");
    const backgroundColor = scElement ? scElement.style.backgroundColor : null;

    if (!scElement || backgroundColor !== "rgb(189, 195, 199)") {
      try {
        const dataAppointment = JSON.parse(
          e.dataTransfer.getData("appointment")
        );
        setSelectedDate(selectedDate);
        setSelectedTime(hour);
        setSelectedTimeClockOffStart(hour);
        setSelectedTimeClockOffEnd(hour);
        setSelectedService(dataAppointment.service);
        setSelectedPackage(dataAppointment.packet);
        setSelectedStaff(staff);
        setSelectedCustomer(dataAppointment.customer);
        setDragDropDeleteAppointmentId(dataAppointment.id);
        setOpenUpdateAppointmentDialog(true);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const appointmentHeaderSticky = () => {
    const tableHeader = document.getElementById("table-employees-header");
    if (tableHeader) {
      const dataOffsetTop = tableHeader.dataset.offsettop;
      if (!dataOffsetTop) {
        tableHeader.dataset.offsettop = tableHeader.offsetTop;
      }
      const headerTop = tableHeader.style.top
        ? parseInt(tableHeader.style.top)
        : 0;
      const headerOffetTop = tableHeader.offsetTop;
      const headerOffetHeight = tableHeader.offsetHeight;
      if (window.scrollY >= headerOffetTop) {
        tableHeader.style.top = window.scrollY - headerOffetHeight + "px";
      } else if (
        headerTop > 0 &&
        dataOffsetTop &&
        window.scrollY < dataOffsetTop
      ) {
        tableHeader.style.top = 0 + "px";
      } else if (headerTop > 0 && window.scrollY < headerOffetTop) {
        tableHeader.style.top = window.scrollY - headerOffetHeight + "px";
      }
    }
  };

  const sendTableOptions = async () => {
    const organizedSelectedStaff = selectedStaffArr.filter((id) => id > 0);
    await Axios.put(`${context.api_endpoint}/staff/sd/update`, {
      selected_staffs: organizedSelectedStaff.toString(),
      divider: widthAppointmentDivider,
    });
    setLoadSettings(false);
  };

  useEffect(() => {
    setNowIndicator(
      `${AppointmentCalendar(
        null,
        contextHourDetail,
        null
      ).getNowIndicatorTopMargin()}%`
    )
  }, [contextHourDetail]);

  useEffect(() => {
    localStorage.setItem("selected_staff", JSON.stringify(selectedStaffArr));
    if (
      selectedStaffArr.length === 0 ||
      selectedStaffArr.length === noUpdatedStaffs.length
    ) {
      localStorage.setItem("all_staff", true);
    } else {
      localStorage.setItem("all_staff", false);
    }
  }, [allStaffSelected, selectedStaffArr]);

  useEffect(() => {
    if (selectedDate) {
      getAppointmentClockTime();
    }
  }, [selectedDate]);

  useEffect(() => {
    setSelectedStaffValues(
      noUpdatedStaffs
        .map((staff) =>
          selectedStaffArr.includes(staff.id) ? staff.full_name : null
        )
        .filter((item) => item !== null)
    );

    localStorage.setItem("selected_date", selectedDate);
    if (selectedStaffArr.length > 0) {
      getAppointmentsForSelectBox();
    }

    if (
      moment(selectedDate).format("DD/MM/YY") === moment().format("DD/MM/YY")
    ) {
      redLineToCenter();
    }
    localStorage.setItem("selected_staff", JSON.stringify(selectedStaffArr));
  }, [selectedStaffArr, selectedDate]);

  useEffect(() => {
    setWidthStaffBox(
      selectedStaffCountWidth.filter(
        (item) => item.selectedWidth_id === widthAppointmentDivider
      )[0]
        ? selectedStaffCountWidth.filter(
          (item) => item.selectedWidth_id === widthAppointmentDivider
        )[0].name
        : `calc(${96.5 / widthAppointmentDivider}vw - ${(125 + widthAppointmentDivider * 0.01) / widthAppointmentDivider
        }px)`
    );
  }, [widthAppointmentDivider]);

  useEffect(() => {
    loadSettings && sendTableOptions();
  }, [widthAppointmentDivider, selectedStaffArr, loadSettings]);

  useEffect(() => {
    getShortcode();
    getTemplates();
    getDividerAndSelectedStaff();
    if (widthAppointmentDivider === null) {
      setDisplayButtons(null);
    }
    getCompanyHours();

    if (localStorage.getItem("selected_filter_picker") === "1") {
      getAppointmentClockTime();
    } else if (localStorage.getItem("selected_filter_picker") === "2") {
      getAppointmentsByWeek();
    } else if (localStorage.getItem("selected_filter_picker") === "3") {
      getAppointmentsByMonth();
    } else {
      toast.error(context.t(`appointments.filterPickerError`));
    }

    setTimeout(() => {
      setSelectedStaffCountWidth((prevWidths) => [
        ...prevWidths,
        {
          selectedWidth_id: -1,
          name: `calc(${96.5 / staffs.length}vw - ${(125 + staffs.length * 0.01) / staffs.length
            }px)`,
        },
      ]);
    }, 2000);

    document.addEventListener("scroll", appointmentHeaderSticky);

    const search = props.location.search;
    const params = new URLSearchParams(search);
    if (params.get("dialog") === "create") {
      setOpenNewAppointmentDialog(true);
    }

    setInterval(() => {
      setNowIndicator(
        `${AppointmentCalendar(
          null,
          contextHourDetail,
          null
        ).getNowIndicatorTopMargin()}%`
      );
    }, 60000);

    setTimeout(() => {
      if (
        moment(selectedDate).format("DD/MM/YY") ===
        moment().format("DD/MM/YY") &&
        weeklyAppointments === false &&
        monthlyAppointments === false
      ) {
        redLineToCenter();
      }
    }, 250);
  }, []);

  useEffect(() => {
    const selected_card = history?.location?.state?.appointment_id;
    if (selected_card) {
      setTimeout(() => {
        const card = document?.getElementById(
          "appointment_card_" + selected_card
        );
        if (card) {
          card.click();
        }

        // appointment_id'yi temizle
        history.replace({
          ...history.location,
          state: { ...history.location.state, appointment_id: undefined },
        });
      }, 1200);
    }
  }, []);

  return (
    <AuthContainer limited_permission="appointment">
      {/* Date Head Bar */}
      <Grid
        className="mb-1 headBar"
        style={{ backgroundColor: "white", zIndex: "12" }}
      >
        <Column className="xs-12 sm-12 md-2">
          <MuiThemeProvider theme={theme}>
            <Tooltip
              title={context.t(`appointments.tooltipExplanations.selectStaff`)}
            >
              <div>
                <FormControl
                  style={{
                    width: "100%",
                    height: "60px",
                  }}
                  className={"borderless-select"}
                >
                  <SampleMuiSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    style={{ position: "relative" }}
                    open={staffSelectDrop}
                    onOpen={() => {
                      setStaffSelectDrop(true);
                      getStaffForSelectBox();
                    }}
                    onClose={() => setStaffSelectDrop(false)}
                    multiple
                    value={selectedStaffValues}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                      if (
                        staffs.length === noUpdatedStaffs.length ||
                        staffs.length === 0
                      ) {
                        return <p>{context.t(`appointments.detail.allStaffSelectedTitle`)}</p>;
                      }
                      return staffs.map((item) => item.full_name).join(", ");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "30px",
                        marginTop: "10px",
                      }}
                    >
                      <Close
                        onClick={() => setStaffSelectDrop(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <MenuItem
                      key={"AllStaff"}
                      onClick={() => {
                        if (allStaffSelected) {
                          setAllStaffSelected(!allStaffSelected);
                          setSelectedStaffArr([]);
                          setSelectedStaffValues([]);
                        } else {
                          setAllStaffSelected(!allStaffSelected);
                          setSelectedStaffArr(
                            noUpdatedStaffs.map((staff) => staff.id)
                          );
                          setSelectedStaffValues(
                            noUpdatedStaffs.map((staff) => staff.full_name)
                          );
                        }
                        setLoadSettings(true);
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={allStaffSelected === true}
                      />
                      Tümü
                    </MenuItem>
                    {noUpdatedStaffs.map((staff) => (
                      <MenuItem
                        key={staff.id}
                        value={staff.id}
                        onClick={() => {
                          if (selectedStaffArr.includes(staff.id)) {
                            setSelectedStaffArr((prevStaffArr) =>
                              prevStaffArr.filter((id) => id !== staff.id)
                            );
                          } else {
                            setSelectedStaffArr((prevStaffArr) => [
                              ...prevStaffArr,
                              staff.id,
                            ]);
                          }
                          setLoadSettings(true);
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            selectedStaffArr.findIndex(
                              (item) => item === staff.id
                            ) > -1
                          }
                        />
                        {staff.full_name}
                      </MenuItem>
                    ))}
                  </SampleMuiSelect>
                </FormControl>
              </div>
            </Tooltip>
          </MuiThemeProvider>
        </Column>

        <Column className="xs-12 sm-12 md-1" style={{ zIndex: 1 }}>
          <MuiThemeProvider theme={theme}>
            <Tooltip
              title={context.t(`appointments.tooltipExplanations.staffCount`)}
            >
              <CostumSelect
                style={{ cursor: "pointer" }}
                value={
                  widthAppointmentDivider === 3
                    ? widthAppointmentDivider
                    : widthAppointmentDivider === 5
                      ? widthAppointmentDivider
                      : widthAppointmentDivider === 7
                        ? widthAppointmentDivider
                        : widthAppointmentDivider === 9
                          ? widthAppointmentDivider
                          : "-1"
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const selectedStaffArrLength = selectedStaffArr.length;

                  setDisplayButtons(value === 0 ? "none" : null);
                  setWidthAppointmentDivider(
                    value !== "-1" ? parseInt(value) : selectedStaffArrLength
                  );
                  setWidthStaffBox(
                    value !== "-1"
                      ? `calc(${96.5 / value}vw - ${(125 + value * 0.01) / value
                      }px)`
                      : `calc(${96.5 / selectedStaffArrLength}vw - ${(125 + selectedStaffArrLength * 0.01) /
                      selectedStaffArrLength
                      }px)`
                  );
                  setLoadSettings(true);
                }}
              >
                <option value={3}>
                  {context.t(`appointments.staffViewCount.person3`)}
                </option>
                <option value={5}>
                  {context.t(`appointments.staffViewCount.person5`)}
                </option>
                <option value={7}>
                  {context.t(`appointments.staffViewCount.person7`)}
                </option>
                <option value={9}>
                  {context.t(`appointments.staffViewCount.person9`)}
                </option>
                <option value={"-1"}>
                  {context.t(`appointments.staffViewCount.all`)}
                </option>
              </CostumSelect>
            </Tooltip>
          </MuiThemeProvider>
        </Column>

        <Column className="xs-12 sm-12 md-2 pr-0">
          <div style={{ display: "flex", alignItems: "center" }}>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={context.t(
                  `appointments.tooltipExplanations.appointmentRequest`
                )}
              >
                <IconButton
                  onClick={() => setOpenOnlineAppointmentRequestDialog(true)}
                >
                  <EventAvailable />
                </IconButton>
              </Tooltip>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={context.t(
                  `appointments.tooltipExplanations.appointmentList`
                )}
              >
                <IconButton
                  onClick={() => setOpenFilterAppointmentDialog(true)}
                >
                  <FilterList />
                </IconButton>
              </Tooltip>
            </MuiThemeProvider>
            {app_name === "salon" && (
              <img
                onClick={() => {
                  setYoutubePopUp(true);
                }}
                src={youtube_image}
                style={{
                  width: "70px",
                  height: "37px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </Column>

        {/* Date Picker arrowLeft and arrowRight */}
        <Column className="xs-12 sm-12 md-3 pl-0">
          {weeklyAppointments === false && monthlyAppointments === false ? (
            <>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      zIndex: "2",
                    }}
                  >
                    <DateArrow
                      onClick={() => {
                        setSelectedDate(
                          new Date(
                            moment(selectedDate)
                              .subtract(1, "days")
                              .toISOString()
                          )
                        );
                      }}
                    >
                      <ChevronLeft
                        color="primary"
                      //style={{ color: app_name === "en" && orange[500] }}
                      />
                    </DateArrow>

                    <DatePickerInput
                      className="mb-0 appointment-date"
                      value={selectedDate}
                      format="DD MMMM dddd"
                      onChange={(selected_date) => {
                        set_Appointment(null);
                        setSelectedDate(selected_date);
                      }}
                    />
                    <DateArrow
                      onClick={() => {
                        setSelectedDate(
                          new Date(
                            moment(selectedDate).add(1, "days").toISOString()
                          )
                        );
                      }}
                    >
                      <ChevronRight color="primary" />
                    </DateArrow>
                  </div>
                  {moment(todayDate).format("DD MMMM dddd") !==
                    moment(selectedDate).format("DD MMMM dddd") && (
                      <TodayButton
                        onClick={() => {
                          setSelectedDate(new Date());
                          if (
                            moment(selectedDate).format("DD/MM/YY") ===
                            moment().format("DD/MM/YY")
                          ) {
                            redLineToCenter();
                          }
                        }}
                      >
                        {context.t(`appointments.today`)}
                      </TodayButton>
                    )}
                </div>
              </Grid>
            </>
          ) : monthlyAppointments === false && weeklyAppointments === true ? (
            <>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <DateArrow
                    onClick={() => {
                      setChangeWeek((prevState) => ({
                        ...prevState,
                        start_date: moment(prevState.start_date)
                          .subtract(1, "week")
                          .startOf("isoWeek")
                          .toDate(),
                        end_date: moment(prevState.end_date)
                          .subtract(1, "week")
                          .endOf("isoWeek")
                          .toDate(),
                      }));

                      getAppointmentsByWeek();
                    }}
                  >
                    <ChevronLeft color="primary" />
                  </DateArrow>

                  <WeekHeader>
                    {changeWeek !== null
                      ? `${moment(changeWeek.start_date).format(
                        "DD MMMM dddd"
                      )} - ${moment(changeWeek.end_date).format(
                        "DD MMMM dddd"
                      )}`
                      : ""}
                  </WeekHeader>

                  <DateArrow
                    onClick={() => {
                      setChangeWeek((prevState) => ({
                        ...prevState,
                        start_date: moment(prevState.start_date)
                          .add(1, "week")
                          .startOf("isoWeek")
                          .toDate(),
                        end_date: moment(prevState.end_date)
                          .add(1, "week")
                          .endOf("isoWeek")
                          .toDate(),
                      }));

                      getAppointmentsByWeek();
                    }}
                  >
                    <ChevronRight color="primary" />
                  </DateArrow>
                </div>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Column>
        {/* Day Week Selection Button */}
        <Column className="xs-12 sm-12 md-1">
          {weeklyAppointments === false && monthlyAppointments === false && (
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <IconWrapper
                disabled={isDisabled}
                onClick={handleClickRefreshButton}
              >
                <RefreshIcon />{" "}
                {context.t("branches.headings.listTable.refresh")}
              </IconWrapper>
            </div>
          )}
        </Column>
        <Column
          className="xs-12 sm-12 md-1"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <MuiThemeProvider theme={theme}>
            <Tooltip
              title={context.t(
                `appointments.tooltipExplanations.appointmentTypes`
              )}
            >
              <FormControl>
                <SampleMuiSelect
                  MenuProps={{ style: { zIndex: 1501 } }}
                  className="filter-picker"
                  style={{ width: "100px" }}
                  inputProps={{ "aria-label": "Without label" }}
                  variant="outlined"
                  value={
                    selectedFilterPicker
                      ? `${selectedFilterPicker.id}`
                      : localStorage.getItem("selected_filter_picker")
                        ? `${localStorage.getItem("selected_filter_picker")}`
                        : 1
                  }
                  onChange={(e) => {
                    if (e.target.value === 1) {
                      setSelectedFilterPicker({ id: 1, label: "Bugün" });

                      localStorage.setItem(
                        "selected_filter_picker",
                        e.target.value
                      );
                      setSelectedDate(new Date());
                      setWeeklyAppointments(false);
                      setMonthlyAppointments(false);
                    } else if (e.target.value === 2) {
                      setSelectedFilterPicker({ id: 2, label: "Bu Hafta" });
                      localStorage.setItem(
                        "selected_filter_picker",
                        e.target.value
                      );
                      getAppointmentsByWeek();
                    } else if (e.target.value === 3) {
                      setSelectedFilterPicker({ id: 3, label: "Bu Ay" });
                      localStorage.setItem(
                        "selected_filter_picker",
                        e.target.value
                      );
                      getAppointmentsByMonth();
                    } else {
                      toast.error(context.t(`appointments.filterPickerError`));
                    }
                  }}
                >
                  <MenuItem value={1}>
                    {context.t(`component.dateRangeStats.today`)}
                  </MenuItem>
                  <MenuItem value={2}>
                    {context.t(`component.dateRangeStats.week`)}
                  </MenuItem>
                  <MenuItem value={3}>
                    {context.t(`component.dateRangeStats.month`)}
                  </MenuItem>
                </SampleMuiSelect>
              </FormControl>
            </Tooltip>
          </MuiThemeProvider>
        </Column>

        <Column className="xs-12 sm-12 md-2">
          <Button
            title={context.t(`appointments.createAppointmentButtonTitle`)}
            textColor="white"
            backgroundColor="primary"
            size="md"
            fullWidth
            onClick={() => setOpenNewAppointmentDialog(true)}
          />
        </Column>
      </Grid>
      {weeklyAppointments === true ? (
        <>
          <WeekAppointment
            selected_id={selectedStaffArr}
            start_date={changeWeek.start_date}
          />
        </>
      ) : monthlyAppointments !== true ? (
        <div
          style={{ overflowX: "auto", maxHeight: "100vh" }}
          id="table-content"
        >
          {loaded ? (
            // Order Employee
            <Grid
              style={{
                marginTop: "-55px",
                maxHeight: "84vh",
              }}
            >
              {/*Yeni Tablo*/}
              <Grid style={{ marginTop: "70px" }} className="xs-12 sm-12 mb-2">
                <StyledTable>
                  <thead>
                    <tr>
                      <StyleCloseClockDiv />
                      {staffs.map((staff, index) => (
                        <StyledTh key={index}>
                          <StyledThContainer>
                            <StaffHeading background={staff.color}>
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                                onClick={() =>
                                  history.push(
                                    `/settings/employees/detail/${staff.id}`
                                  )
                                }
                                src={
                                  staff.detail.profile_photo
                                    ? `${context.api_endpoint.replace(
                                      "api/v1",
                                      ""
                                    )}/${context.state.company_id}/${staff.detail.profile_photo
                                    }`
                                    : staff.sex === 0
                                      ? require("../../../assets/images/staff_woman.jpg")
                                      : staff.sex === 1
                                        ? require("../../../assets/images/staff_man.jpg")
                                        : require("../../../assets/images/profile_photo.svg")
                                }
                                alt={staff.full_name}
                              />
                              <h3>
                                {staff.full_name.length > 10
                                  ? staff.full_name.substring(0, 9) + "..."
                                  : staff.full_name}
                              </h3>
                              <p className="appointment-count-bagde">
                                <span>
                                  {staffs.find(
                                    (item) => item.id === staff.id
                                  ) === undefined
                                    ? 0
                                    : staffs.find(
                                      (item) => item.id === staff.id
                                    ).appointments.length}
                                </span>
                              </p>
                            </StaffHeading>
                          </StyledThContainer>
                        </StyledTh>
                      ))}
                    </tr>
                  </thead>
                  <StyledTBody id="TestContainer">
                    {moment(selectedDate).tz("Europe/Istanbul").format("LL") ===
                      moment(new Date()).tz("Europe/Istanbul").format("LL") && (
                        <DateNowIndicator
                          ref={redLine}
                          style={{
                            zIndex: 999,
                            top: nowIndicator,
                            background: "red",
                            width: "100%",
                          }}
                        />
                      )}
                    {hours.map((hour, indexRow) => (
                      <tr>
                        <StyledClockTh style={{ zIndex: 102 }}>
                          <StyledTimeContainer item>
                            <StyledTimeText>{hour}</StyledTimeText>
                          </StyledTimeContainer>
                        </StyledClockTh>
                        {staffs.map((staff, index) => (
                          <ScheduleContainer
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                staff.closed_appointments?.un_appointments &&
                                  (JSON.parse(
                                    staff.closed_appointments?.un_appointments
                                  )[
                                    new Date(selectedDate).getDay() !== 0
                                      ? new Date(selectedDate).getDay()
                                      : 7
                                  ]?.active
                                    ? JSON.parse(
                                      staff.closed_appointments?.un_appointments
                                    )[
                                      new Date(selectedDate).getDay() !== 0
                                        ? new Date(selectedDate).getDay()
                                        : 7
                                    ]?.hours.includes(hour)
                                    : !JSON.parse(
                                      staff.closed_appointments?.un_appointments
                                    )[
                                      new Date(selectedDate).getDay() !== 0
                                        ? new Date(selectedDate).getDay()
                                        : 7
                                    ]?.active)
                                  ? "#d9d9d9"
                                  : new Date(
                                    `${moment(selectedDate)
                                      .tz("Europe/Istanbul")
                                      .format("YYYY-MM-DD")} ${hour}`
                                  ) <
                                  new Date(
                                    `${moment()
                                      .tz("Europe/Istanbul")
                                      .format("YYYY-MM-DD HH:mm")}`
                                  ) && "rgb(236, 236, 236)",
                            }}
                            key={Math.random() + "_ti_sdkjs_" + Math.random()}
                            largerow={tableLargeRow ? 1 : 0}
                            week={0}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDropCapture={handleDropCapture}
                            onDrop={(e) => handleDrop(e, hour, staff)}
                            onClick={(e) => {
                              //  Her saate randevu alınma durumu kontrolü
                              if (
                                staffs.appointments &&
                                staffs.appointments.length > 0 &&
                                staffs.appointments
                                  .map(
                                    (item) =>
                                      hour ===
                                      moment(item.appointment_start_date)
                                        .tz("Europe/Istanbul")
                                        .format("HH:mm")
                                  )
                                  .filter((item) => item === true).length > 0
                              ) {
                                toast.warning(
                                  context.t(`appointments.create.onCreateErrorToast`),
                                  {
                                    autoClose: 10 * 1000,
                                  }
                                );
                              }

                              if (
                                !e.target?.className
                                  .split(" ")
                                  .includes("sc-hJZKUC")
                              ) {
                                setSelectedStaff(staff);
                                setSelectedTime(hour);
                                setSelectedTimeClockOffStart(hour);
                                setSelectedTimeClockOffEnd(hour);
                                setWeeklyAppointmentsSelectedDate(staff.date);
                                setCreateAppointmentModal(true);
                              } else if (staff === null) {
                                toast.warning(
                                  context.t(`appointments.create.selectStaffWarnToast`)
                                );
                              }
                            }}
                          >
                            <Grid
                              item
                              key={staff}
                              style={{
                                width: widthStaffBox,
                                height: "7vh",
                              }}
                            >
                              {staff.closed_appointments?.un_appointments &&
                                (JSON.parse(
                                  staff.closed_appointments?.un_appointments
                                )[
                                  new Date(selectedDate).getDay() !== 0
                                    ? new Date(selectedDate).getDay()
                                    : 7
                                ]?.active
                                  ? JSON.parse(
                                    staff.closed_appointments?.un_appointments
                                  )[
                                    new Date(selectedDate).getDay() !== 0
                                      ? new Date(selectedDate).getDay()
                                      : 7
                                  ]?.hours.includes(hour)
                                  : !JSON.parse(
                                    staff.closed_appointments?.un_appointments
                                  )[
                                    new Date(selectedDate).getDay() !== 0
                                      ? new Date(selectedDate).getDay()
                                      : 7
                                  ]?.active) && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "0px",
                                      height: "0px",
                                    }}
                                  >
                                    <Tooltip
                                      title={context.t(`appointments.tooltipExplanations.closeToGetAppointment`)}
                                      placement="right"
                                    >
                                      <div
                                        style={{
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "8px",
                                          backgroundColor: `rgb(242,242,247)`,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          boxShadow:
                                            "2px 2px 5px rgba(0, 0, 0, 0.3)",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "cover",
                                          }}
                                          src={closedTimeCutomer}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}

                              {staff.appointments
                                .filter((el) => {
                                  const nextHour =
                                    hours[indexRow + 1]?.replace(":", "") ==
                                      "0000"
                                      ? 2400
                                      : parseInt(
                                        hours[indexRow + 1]?.replace(":", "")
                                      );
                                  const currentHour = parseInt(
                                    hour.replace(":", "")
                                  );
                                  const appointmentTime = parseInt(
                                    moment(el.repeat_date)
                                      .tz("Europe/Istanbul")
                                      .format("HH:mm")
                                      .replace(":", "")
                                  );

                                  if (nextHour < currentHour) {
                                    return (
                                      currentHour <= appointmentTime ||
                                      appointmentTime < nextHour
                                    );
                                  }

                                  if (indexRow === hours.length - 2) {
                                    return (
                                      nextHour >= appointmentTime &&
                                      appointmentTime >= currentHour
                                    );
                                  } else {
                                    return (
                                      nextHour > appointmentTime &&
                                      appointmentTime >= currentHour
                                    );
                                  }
                                })

                                .map((appointment, AppoIndex) => {
                                  let appointmentIndexFromGroup =
                                    staff.appointments.indexOf(appointment);
                                  return (
                                    <>
                                      <MuiThemeProvider
                                        theme={themeForCardDetail}
                                      >
                                        <Tooltip
                                          placement="top"
                                          key={AppoIndex}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: 0,
                                            position: "relative",
                                            backgroundColor: "white !important",
                                          }}
                                          title={
                                            <div
                                              style={{
                                                padding: "5px",
                                                backgroundColor:
                                                  appointment.customer_state ===
                                                    0
                                                    ? "#CBECC9"
                                                    : appointment.customer_state ===
                                                      1
                                                      ? "#ED8286"
                                                      : appointment.customer_state ===
                                                        3
                                                        ? "#D6FAFA"
                                                        : appointment.customer_state ===
                                                          4
                                                          ? "#EDDEFC"
                                                          : "#FFDDB0",
                                                borderRadius: "10px",
                                                boxShadow:
                                                  "0px 0px 5px rgba(0,0,0,0.2)",
                                              }}
                                            >
                                              <TooltipCardInner>
                                                <TooltipCardRow
                                                  style={{
                                                    justifyContent: "normal",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "16px",
                                                        marginTop: "5px",
                                                        paddingBottom: "5px",
                                                        lineHeight: "150%",
                                                      }}
                                                    >
                                                      {
                                                        appointment.customer
                                                          ?.full_name
                                                      }
                                                    </span>
                                                    <span>
                                                      {moment(
                                                        appointment.appointment_start_date
                                                      )
                                                        .tz("Europe/Istanbul")
                                                        .format("HH:mm")}
                                                      -
                                                      {moment(
                                                        appointment.appointment_end_date
                                                      )
                                                        .tz("Europe/Istanbul")
                                                        .format("HH:mm")}
                                                      {" / "}
                                                      {appointment.customer_state ===
                                                        0 ? (
                                                        <span
                                                          style={{
                                                            fontSize: "12px",
                                                            color: "#528859",
                                                          }}
                                                        >
                                                          {context.t(`appointments.detail.statusAttended`)}
                                                        </span>
                                                      ) : (
                                                        <>
                                                          {appointment.customer_state ===
                                                            1 ? (
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                                color:
                                                                  "#9A2020",
                                                              }}
                                                            >
                                                              {context.t(`appointments.detail.statusNotAttended`)}
                                                            </span>
                                                          ) : (
                                                            <>
                                                              {appointment.customer_state ===
                                                                3 ? (
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "12px",
                                                                    color:
                                                                      "#0b8bc1",
                                                                  }}
                                                                >
                                                                  {context.t(`appointments.detail.statusAccepted`)}
                                                                </span>
                                                              ) : (
                                                                <>
                                                                  {appointment.customer_state ===
                                                                    4 ? (
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "12px",
                                                                        color:
                                                                          "#c4aec0",
                                                                      }}
                                                                    >
                                                                      {context.t(`appointments.detail.statusInProcess`)}
                                                                    </span>
                                                                  ) : (
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "12px",
                                                                        color:
                                                                          "#995A1F",
                                                                      }}
                                                                    >
                                                                      {context.t(`appointments.detail.statusNew`)}
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </span>
                                                  </div>
                                                </TooltipCardRow>
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(162, 162, 162, 0.12)",
                                                    marginBottom: "5px",
                                                  }}
                                                ></div>

                                                <TooltipCardRow>
                                                  <span className="limited-line-1">
                                                    {appointment.service &&
                                                      appointment.service.name}
                                                  </span>
                                                  {((context.state.user
                                                    .permission === 2 &&
                                                    context.state
                                                      .limited_permission
                                                      .customer_info !== 0) ||
                                                    context.state.user
                                                      .permission !== 2) && (
                                                      <span
                                                        style={{
                                                          marginLeft: "105px",
                                                        }}
                                                      >
                                                        0
                                                        {
                                                          appointment.customer
                                                            ?.phone
                                                        }
                                                      </span>
                                                    )}
                                                </TooltipCardRow>
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(162, 162, 162, 0.12)",
                                                    marginBottom: "5px",
                                                    marginTop: "5px",
                                                  }}
                                                ></div>
                                                <TooltipCardRow>
                                                  {context.t(`appointments.descriptionNoteTitle`)}
                                                  <span
                                                    style={{
                                                      marginLeft: "10px",
                                                      marginTop: "-2px",
                                                    }}
                                                  >
                                                    {appointment.note
                                                      ? appointment.note
                                                      : "-"}
                                                  </span>
                                                </TooltipCardRow>
                                              </TooltipCardInner>
                                            </div>
                                          }
                                          arrow
                                        >
                                          <AppointmentCard
                                            id={
                                              "appointment_card_" +
                                              appointment.id
                                            }
                                            staffCount={staffs.length}
                                            largerow={tableLargeRow ? 1 : 0}
                                            draggable={true}
                                            onDragStart={(e) =>
                                              handleDragStart(e, appointment)
                                            }
                                            onDragCapture={handleDragCapture}
                                            onDragEnd={(e) =>
                                              handleDragEnd(e, appointment)
                                            }
                                            key={
                                              staffs.indexOf(staff) +
                                              "-" +
                                              appointment.appointment_start_date +
                                              index
                                            }
                                            backgroundColor={AppointmentCalendar(
                                              appointment
                                            ).getCardBackgroundColor()}
                                            textColor={AppointmentCalendar(
                                              appointment
                                            ).getCardTextColor()}
                                            style={{
                                              ...AppointmentCalendar(
                                                appointment,
                                                contextHourDetail,
                                                staff.appointments,
                                                {
                                                  groupCount:
                                                    staff.appointments.length,
                                                  appointmentIndexFromGroup,
                                                  staffIndexOf:
                                                    staffs.indexOf(staff),
                                                },
                                                tableLargeRow,
                                                staffs.length
                                              ).getStyle(),
                                              marginLeft: `calc(${76 /
                                                widthAppointmentDivider /
                                                staff.appointments.filter(
                                                  (el) => {
                                                    const nextHour =
                                                      hours[
                                                        indexRow + 1
                                                      ]?.replace(":", "") ==
                                                        "0000"
                                                        ? 2400
                                                        : parseInt(
                                                          hours[
                                                            indexRow + 1
                                                          ]?.replace(":", "")
                                                        );
                                                    const currentHour =
                                                      parseInt(
                                                        hour.replace(":", "")
                                                      );
                                                    const appointmentTime =
                                                      parseInt(
                                                        moment(el.repeat_date)
                                                          .tz("Europe/Istanbul")
                                                          .format("HH:mm")
                                                          .replace(":", "")
                                                      );

                                                    if (
                                                      nextHour < currentHour
                                                    ) {
                                                      return (
                                                        currentHour <=
                                                        appointmentTime ||
                                                        appointmentTime <
                                                        nextHour
                                                      );
                                                    }

                                                    if (
                                                      indexRow ===
                                                      hours.length - 2
                                                    ) {
                                                      return (
                                                        nextHour >=
                                                        appointmentTime &&
                                                        appointmentTime >=
                                                        currentHour
                                                      );
                                                    } else {
                                                      return (
                                                        nextHour >
                                                        appointmentTime &&
                                                        appointmentTime >=
                                                        currentHour
                                                      );
                                                    }
                                                  }
                                                ).length
                                                }vw * ${AppoIndex})`,
                                              width: `${76 /
                                                widthAppointmentDivider /
                                                staff.appointments.filter(
                                                  (el) => {
                                                    const nextHour =
                                                      hours[
                                                        indexRow + 1
                                                      ]?.replace(":", "") ==
                                                        "0000"
                                                        ? 2400
                                                        : parseInt(
                                                          hours[
                                                            indexRow + 1
                                                          ]?.replace(":", "")
                                                        );
                                                    const currentHour =
                                                      parseInt(
                                                        hour.replace(":", "")
                                                      );
                                                    const appointmentTime =
                                                      parseInt(
                                                        moment(el.repeat_date)
                                                          .tz("Europe/Istanbul")
                                                          .format("HH:mm")
                                                          .replace(":", "")
                                                      );

                                                    if (
                                                      nextHour < currentHour
                                                    ) {
                                                      return (
                                                        currentHour <=
                                                        appointmentTime ||
                                                        appointmentTime <
                                                        nextHour
                                                      );
                                                    }

                                                    if (
                                                      indexRow ===
                                                      hours.length - 2
                                                    ) {
                                                      return (
                                                        nextHour >=
                                                        appointmentTime &&
                                                        appointmentTime >=
                                                        currentHour
                                                      );
                                                    } else {
                                                      return (
                                                        nextHour >
                                                        appointmentTime &&
                                                        appointmentTime >=
                                                        currentHour
                                                      );
                                                    }
                                                  }
                                                ).length
                                                }vw`,
                                            }}
                                            onClick={() => {
                                              setHourDetail({
                                                start_hour: moment(
                                                  appointment.appointment_start_date
                                                ).format("HH:mm"),
                                                end_hour: moment(
                                                  appointment.appointment_end_date
                                                ).format("HH:mm"),
                                              });
                                              set_Appointment(appointment);

                                              setAppointmentDate(
                                                appointment.appointment_start_date
                                              );
                                              setSelectedStaff(staff);

                                              setAppointmentDetailModal(true);
                                            }}
                                          >
                                            <AppointmentCardInner>
                                              <AppointmentRow>
                                                {/* <Person /> */}
                                                <span
                                                  className="limited-line-4"
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {
                                                    appointment.customer
                                                      ?.full_name
                                                  }
                                                </span>
                                                {appointment.customer_state ===
                                                  0 ? (
                                                  <img
                                                    src={HomeChcek}
                                                    alt="home-check"
                                                  />
                                                ) : (
                                                  <>
                                                    {appointment.customer_state ===
                                                      1 ? (
                                                      <img
                                                        src={NotCome}
                                                        alt="note-come"
                                                      />
                                                    ) : (
                                                      <>
                                                        {appointment.customer_state ===
                                                          3 ? (
                                                          <img
                                                            src={Approved}
                                                            alt="approved"
                                                          />
                                                        ) : (
                                                          <>
                                                            {appointment.customer_state ===
                                                              4 ? (
                                                              <img
                                                                style={{
                                                                  transform:
                                                                    "scaleX(-1)",
                                                                }}
                                                                src={Process}
                                                                alt="process"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={Waiting}
                                                                alt="waiting"
                                                              />
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </AppointmentRow>

                                              <AppointmentRow>
                                                <span>
                                                  {appointment.service &&
                                                    appointment.service.name}
                                                </span>
                                              </AppointmentRow>

                                              <AppointmentRow>
                                                {/* <AccessAlarm /> */}
                                                <span>
                                                  {moment(
                                                    appointment.appointment_start_date
                                                  )
                                                    .tz("Europe/Istanbul")
                                                    .format("HH:mm")}{" "}
                                                  -{" "}
                                                  {moment(
                                                    appointment.appointment_end_date
                                                  )
                                                    .tz("Europe/Istanbul")
                                                    .format("HH:mm")}
                                                </span>
                                              </AppointmentRow>
                                            </AppointmentCardInner>
                                          </AppointmentCard>
                                        </Tooltip>
                                      </MuiThemeProvider>
                                    </>
                                  );
                                })}
                              {clockOff
                                .filter((el) => el.staff_id === staff.id)
                                .map((clock) => {
                                  return (
                                    <>
                                      <AppointmentCard
                                        staffCount={staffs.length}
                                        largerow={tableLargeRow ? 1 : 0}
                                        key={
                                          clockOff.indexOf(clock) +
                                          "-" +
                                          clock.date
                                        }
                                        onMouseOver={() => {
                                          if (infoMessageSeen === false) {
                                            setInfoMessageSeen(true);
                                            toast.info(
                                              context.t(
                                                `appointments.onMouseOverCardToast`
                                              )
                                            );
                                          }
                                        }}
                                        style={{
                                          background: "rgba(189, 195, 199)",
                                          color: "black",
                                          width: widthStaffBox,
                                          ...AppointmentCalendar(
                                            clock,
                                            contextHourDetail,
                                            clockOff,
                                            {
                                              groupCount: 1,
                                              appointmentIndexFromGroup: 0,
                                              staffIndexOf: staffs.indexOf(
                                                staffs.filter(
                                                  (staff) =>
                                                    staff.id === clock.staff_id
                                                )[0]
                                              ),
                                            },
                                            tableLargeRow,
                                            staffs.length,
                                            true
                                          ).getStyle(),
                                        }}
                                        onClick={() => {
                                          setSelectedClock(clock);
                                          const selectedStaffData =
                                            staffs.filter(
                                              (staff) =>
                                                staff.id === clock.staff_id
                                            )[0];
                                          setSelectedStaff(selectedStaffData);
                                          setAppointmentDetailModalClock(true);
                                        }}
                                      >
                                        <AppointmentCardInner
                                          style={{ marginTop: "20px" }}
                                        >
                                          <AppointmentRow>
                                            <Person />
                                            <span className="limited-line-1">
                                              {staffs.filter((staff) => {
                                                return (
                                                  staff.id === clock.staff_id
                                                );
                                              })[0]
                                                ? staffs.filter((staff) => {
                                                  return (
                                                    staff.id ===
                                                    clock.staff_id
                                                  );
                                                })[0].full_name
                                                : "-"}
                                            </span>
                                          </AppointmentRow>

                                          {clock.all_hours_closed === false ? (
                                            <AppointmentRow>
                                              <AccessAlarm />
                                              <span>
                                                {moment(clock.start_hour)
                                                  .tz("Europe/Istanbul")
                                                  .format("HH:mm")}{" "}
                                                -{" "}
                                                {moment(clock.end_hour)
                                                  .tz("Europe/Istanbul")
                                                  .format("HH:mm")}
                                              </span>
                                            </AppointmentRow>
                                          ) : (
                                            <AppointmentRow>
                                              <AccessAlarm />
                                              <span>{context.t(`appointments.allDayClosedText`)}</span>
                                            </AppointmentRow>
                                          )}
                                          <AppointmentRow>
                                            <Description />
                                            <span className="limited-line-1">
                                              {clock.note ? clock.note : "-"}
                                            </span>
                                          </AppointmentRow>
                                        </AppointmentCardInner>
                                      </AppointmentCard>
                                    </>
                                  );
                                })}
                            </Grid>
                          </ScheduleContainer>
                        ))}
                      </tr>
                    ))}
                  </StyledTBody>
                  <Grid
                    container
                    style={{
                      display: displayButtons,
                      position: "fixed",
                      marginLeft: "70px",
                      top: "50%",
                      width: "60px",
                      zIndex: "101",
                    }}
                  >
                    {staffs.length >= 4 ? (
                      <Tooltip
                        title={context.t(`appointments.leftButtonTooltip`)}
                      >
                        <TableButtonLeft
                          onClick={() =>
                            document.getElementById("table-content").scrollBy({
                              top: 0,
                              left:
                                (-window.innerWidth + 60) / selectedStaffCount,
                              behavior: "smooth",
                            })
                          }
                        >
                          <ChevronLeft size="large" />
                        </TableButtonLeft>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: displayButtons,
                      position: "fixed",
                      top: "50%",
                      right: "10px",
                      width: "60px",
                      zIndex: "101",
                    }}
                  >
                    {staffs.length >= 4 ? (
                      <Tooltip
                        title={context.t(`appointments.rightButtonTooltip`)}
                      >
                        <TableButtonRight
                          onClick={() =>
                            document.getElementById("table-content").scrollBy({
                              top: 0,
                              left:
                                (window.innerWidth - 60) / selectedStaffCount,
                              behavior: "smooth",
                            })
                          }
                        >
                          <ChevronRight size="large" />
                        </TableButtonRight>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </StyledTable>
              </Grid>
              <Column className="xs-12 sm-12 mt-4 mb-2">
                <MuiThemeProvider theme={theme}>
                  <Tooltip
                    style={{ marginTop: "-15px", marginLeft: "15px" }}
                    placement="top-end"
                    title={
                      <div>
                        <ColorMean>
                          <div
                            className="color"
                            style={{ backgroundColor: "#FFDDB0" }}
                          >
                            <img src={Waiting} alt="waiting" />
                            <div className="overlay" />
                          </div>
                          <span>
                            {context.t(
                              (context.current_flag &
                                context.state.FLAGS.SALON_RANDEVU &&
                                `appointments.definitionYellowText`) ||
                              `appointments.definitionYellowText_en`
                            )}
                          </span>
                        </ColorMean>

                        <ColorMean>
                          <div
                            className="color"
                            style={{ backgroundColor: "#D6FAFA" }}
                          >
                            <img src={Approved} alt="approved" />
                            <div className="overlay" />
                          </div>
                          <span>
                            {context.t(
                              (context.current_flag &
                                context.state.FLAGS.SALON_RANDEVU &&
                                `appointments.definitionGreenText`) ||
                              `appointments.definitionGreenText_en`
                            )}
                          </span>
                        </ColorMean>

                        <ColorMean>
                          <div
                            className="color"
                            style={{ backgroundColor: "#CBECC9" }}
                          >
                            <img src={HomeChcek} alt="home-check" />
                            <div className="overlay" />
                          </div>
                          <span>
                            {context.t("appointments.definitionLightText")}
                          </span>
                        </ColorMean>

                        <ColorMean>
                          <div
                            className="color"
                            style={{ backgroundColor: "#EDDEFC" }}
                          >
                            <img
                              style={{
                                transform: "scaleX(-1)",
                              }}
                              src={Process}
                              alt="process"
                            />
                            <div className="overlay" />
                          </div>
                          <span>
                            {" "}
                            {context.t("appointments.definitionPurpleText")}
                          </span>
                        </ColorMean>

                        <ColorMean>
                          <div
                            className="color"
                            style={{
                              backgroundColor: "#ED8286",
                            }}
                          >
                            <img src={NotCome} alt="note-come" />
                            <div className="overlay" />
                          </div>
                          <span>
                            {context.t(
                              (context.current_flag &
                                context.state.FLAGS.SALON_RANDEVU &&
                                `appointments.definitionRedText`) ||
                              `appointments.definitionRedText_en`
                            )}
                          </span>
                        </ColorMean>
                      </div>
                    }
                  >
                    <IconButton>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </MuiThemeProvider>
              </Column>
            </Grid>
          ) : (
            <AlignContainer>
              <img
                src={
                  process.env.APP_NAME === "salon"
                    ? Loader
                    : process.env.APP_NAME === "en"
                      ? LoaderEN
                      : LoaderSM
                }
                width="100"
                height="100"
                alt="loading"
              />
            </AlignContainer>
          )}
        </div>
      ) : (
        <>
          <MonthAppointment
            hour_detail={hourDetail}
            selected_id={selectedStaffArr}
          />
        </>
      )}
      {/* Create Appointment Modal */}
      <AddAppointmentDialog
        selectedDate={selectedDate}
        selectedTimeClockOffStart={selectedTimeClockOffStart}
        setSelectedTimeClockOffStart={setSelectedTimeClockOffStart}
        selectedTimeClockOffEnd={selectedTimeClockOffEnd}
        selectedStaff={selectedStaff}
        setSelectedStaff={setSelectedStaff}
        weeklyAppointments={weeklyAppointments}
        weeklyAppointmentsSelectedDate={weeklyAppointmentsSelectedDate}
        selectedTime={selectedTime}
        getAppointmentsForSelectBox={getAppointmentsForSelectBox}
        getAppointmentClockTime={getAppointmentClockTime}
        setCreateAppointmentModal={setCreateAppointmentModal}
        createAppointmentModal={createAppointmentModal}
        setSelectedTime={setSelectedTime}
        hours={hours}
        setSelectedTimeClockOffEnd={setSelectedTimeClockOffEnd}
        staffs={staffs}
      />
      {/* Randevu Kartı Detay */}
      <AppointmentCardDetail
        _appointment={_appointment}
        set_Appointment={set_Appointment}
        updateAppointmentState={updateAppointmentState}
        setAppointmentDetailModal={setAppointmentDetailModal}
        setCreateAppointmentModal={setCreateAppointmentModal}
        appointmentDetailModal={appointmentDetailModal}
        hourDetail={hourDetail}
        setHourDetail={setHourDetail}
        weeklyAppointments={weeklyAppointments}
        timeUpgrade={timeUpgrade}
        setTimeUpgrade={setTimeUpgrade}
        selectedStaff={selectedStaff}
        getAppointmentsForSelectBox={getAppointmentsForSelectBox}
        setAppointmentDate={setAppointmentDate}
        appointmentDate={appointmentDate}
        shortCode={shortCode}
        smsTemplates={smsTemplates}
      />
      {/* Randevu Kartı Detay */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        title={context.t(`appointments.detail.clockOffDetail`)}
        open={appointmentDetailModalClock}
        closeHandler={() => setAppointmentDetailModalClock(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.deleteButton`),
            icon: "check",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => {
              selectedClock.is_recurrent === true
                ? setRecurenctModal(true)
                : deleteAppointmentClockTime(selectedClock.id);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.closeButtonTitle`),
            icon: "close",
            backgroundColor: "black-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {selectedClock && (
          <>
            <div style={{ display: "flex" }}>
              <DetailCard
                icon="alarm"
                color="teal"
                label={context.t(`appointments.detail.clockOffDateInputLabel`)}
                value={`${moment(selectedClock.start_hour)
                  .tz("Europe/Istanbul")
                  .format("LLL")} - ${moment(selectedClock.end_hour)
                    .tz("Europe/Istanbul")
                    .format("HH:mm")}`}
              />
            </div>

            <DetailCard
              icon="how_to_reg"
              color="blue"
              label={context.t(`appointments.detail.clockOffStaffInputLabel`)}
              value={selectedStaff?.full_name ?? "-"}
            />

            <DetailCard
              icon="description"
              color="orange"
              label={context.t(`appointments.detail.clockOffNoteInputLabel`)}
              value={
                selectedClock.note !== null &&
                  selectedClock.note !== undefined &&
                  selectedClock.note !== ""
                  ? selectedClock.note
                  : "-"
              }
            />
          </>
        )}
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        open={recurenctModal}
        closeHandler={() => setRecurenctModal(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: `${context.t(`appointments.recurenctModalAccept`)}`,
            icon: "check",
            backgroundColor: "green-opacity",
            textColor: "green",
            onClick: () => {
              deleteAppointmentClockTime(selectedClock.id, 1);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: `${context.t(`appointments.recurenctModalRefuse`)}`,
            icon: "close",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => {
              deleteAppointmentClockTime(selectedClock.id);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: `${context.t(`appointments.recurenctModalCancel`)}`,
            icon: "close",
            backgroundColor: "grey-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        <Alert severity="warning">
          <p>
            {context.t(`appointments.recurenctModalWarn`)}
          </p>
        </Alert>
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`appointments.updateAppointment.title`)}
        open={openUpdateAppointmentDialog}
        closeHandler={() => setOpenUpdateAppointmentDialog(false)}
      >
        <Alert severity="warning">
          <b>{context.t(`appointments.updateAppointment.alertBoldText`)}</b>
          <br />
          {context.t(`appointments.updateAppointment.alertText`)} <br />
          {selectedService &&
            `${context.t(`appointments.updateAppointment.service`)}: ${selectedService.name
            }`}
          {selectedPackage && `${"Paket Adı"}: ${selectedPackage?.name}`}
          <br />
          {selectedStaff &&
            `${context.t(`appointments.updateAppointment.staff`)}: ${selectedStaff.full_name
            }`}
          <br />
          {selectedTime &&
            `${context.t(
              `appointments.updateAppointment.time`
            )}: ${selectedTime}`}{" "}
          <br />
          {selectedCustomer &&
            `${context.t(`appointments.updateAppointment.customer`)}: ${selectedCustomer.full_name
            }`}
        </Alert>

        {/* Add this section because removed buttons section */}
        <Grid className="mt-3">
          <Column className="sx-12 sm-12 md-12 lg-6">
            <Button
              fullWidth
              textColor="primary"
              backgroundColor="primary-opacity"
              icon={<Check />}
              title={context.t(`appointments.recurenctModalAccept`)}
              onClick={() => {
                setOpenUpdateAppointmentDialog(false);
                setTimeout(() => {
                  updateAppointment();
                  setUpdatingApp(true);
                }, 250);
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-6">
            <Button
              fullWidth
              textColor="grey"
              icon={<CloseIcon />}
              title={context.t(`appointments.recurenctModalRefuse`)}
              onClick={() => setOpenUpdateAppointmentDialog(false)}
            />
          </Column>
        </Grid>
      </AlertDialog>
      {/*  Select period on packages */}
      {app_name === "salon" && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={youtubePopUp}
          onClose={() => setYoutubePopUp(false)}
        >
          <div style={{ backgroundColor: "black" }}>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <IoMdClose
                style={{
                  cursor: "pointer",
                  marginRight: "2px",
                  marginTop: "2px",
                }}
                onClick={() => setYoutubePopUp(false)}
                size={"24px"}
                color="white"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"kuEh4uQQMlI"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle2`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"afBhNMTomfc"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle3`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"vngBLPm-q0E"} opts={opts} />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <NewAppointmentDialog
        open={openNewAppointmentDialog}
        history={history}
        staff={staffs.length > 0 ? staffs : null}
        onNewAppointment={() => {
          setOpenNewAppointmentDialog(false);
          getAppointmentsByDate();
        }}
        onClose={() => setOpenNewAppointmentDialog(false)}
      />
      <OnlineAppointmentRequestDailog
        open={openOnlineAppointmentRequestDialog}
        onClose={() => {
          setOpenOnlineAppointmentRequestDialog(false);
        }}
      />
      <FilterAppointment
        open={openFilterAppointmentDialog}
        onClose={() => {
          setOpenFilterAppointmentDialog(false);
        }}
      />
    </AuthContainer>
  );
};

export const COLUMN_WIDTH_12 = 126;
export const COLUMN_WIDTH_11 = 138;
export const COLUMN_WIDTH_10 = 152;
export const COLUMN_WIDTH_9 = 169;
export const COLUMN_WIDTH_8 = 190;
export const COLUMN_WIDTH_7 = 218;
export const COLUMN_WIDTH_6 = 254;
export const COLUMN_WIDTH_5 = 305;
export const COLUMN_WIDTH_4 = 382;
export const COLUMN_WIDTH_3 = 510;
export const COLUMN_WIDTH_2 = 760;
export const COLUMN_WIDTH_1 = 1520;
export const COLUMN_WIDTH = 250;
export const COLUMN_WIDTH_WEEK = 218;
export const SMALL_COLUMN_WIDTH = 1000;
export const LARGE_COLUMN_WIDTH = 1150;
export const XLARGE_COLUMN_WIDTH = 1400;
export const DEFAULT_BACKGROUND_COLOR = "#f5f5f5";
export const DEFAULT_BACKGROUND_COLOR_DARK = "#000000";

const CostumSelect = styled.select`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  height: 56px;
  margin-bottom: 0px;
  margin-top: -2px;
`;

const DateArrow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${app_name === "salon"
    ? "2px solid #3189ce"
    : app_name === "en"
      ? "2px solid orange"
      : "2px solid #ed1e79"};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: auto 5px;
`;

const TodayButton = styled.div`
  cursor: pointer;
  width: 50%;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 5px;
  z-index: 2;
`;

const StyledTable = styled.table`
  z-index: 1;
  text-align: left;
  position: relative;
  border-collapse: collapse;
`;

const StyleCloseClockDiv = styled.div`
  z-index: 3;
  width: 45px;
  height: 50px;
  background-color: transparent;
  @media only screen and (max-width: 576px) {
    height: 40px;
  }
  @media only screen and (min-width: 1920px) {
    height: 50px;
  }
`;

const StyledTh = styled.th`
  z-index: 9999;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  text-align: center;
  padding-bottom: 15px;
  background: transparent;
  position: sticky;
  top: 0px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
`;

const StyledThContainer = styled.div`
  display: flex;
  height: 7vh;
  align-items: center;
  justify-content: center;
  padding: 0px;
`;
const arrays = [1, 2, 3, 4, 5, 6];

const StyledTBody = styled.tbody`
  width: ${arrays.length * 12}vh !important;
  position: relative;
  overflow-x: scroll;
`;

const StyledClockTh = styled.th`
  z-index: 8;
  position: sticky;
  left: 0px; /* Don't forget this, required for the stickiness */
  background-color: white;
`;

const StyledTimeContainer = styled(Grid)`
  position: relative;
  height: 45px;
`;

const StyledTimeText = styled(Typography)`
  position: absolute;
  top: -2vh;
  margin-left: 4px;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600;
  z-index: 1;
  @media only screen and (max-width: 576px) {
    top: -5vh;
  }
  @media only screen and (min-width: 1920px) {
    top: -3.3vh;
  }
`;

const ScheduleContainer = styled.td`
  background-color: white;
  border: 1px solid #bbb;
`;

const WeekHeader = styled.span`
  background-color: #fff;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  color: #3189ec;
`;
const StaffHeading = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    background: #fafafa;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
    border-width: 3px;
    border-style: solid;
    border-color: ${(props) =>
    props.background ? props.background : DEFAULT_BACKGROUND_COLOR};
  }

  h3 {
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: ${DEFAULT_BACKGROUND_COLOR_DARK};
  }

  .appointment-count-bagde {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 8px;
    background: #FFF6ED;
    color: #6689EC;
    border-radius: 50%
  }
}
`;

const AppointmentCard = styled.div`
  width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.staffCount === 1
        ? COLUMN_WIDTH_1
        : COLUMN_WIDTH}px;

  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  padding: 5px;

  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-bottom: 0;
  border: 1px solid #c0c0c0;
  box-shadow: 0 0px 5px 0 ${(props) => props.backgroundColor},
    inset 0 0px 2px 0 #ffffff;
  border-radius: 8px;

  transition: transform 0.5s, margin 0.5s, width 0.5s;

  &:hover {
    transform: scale(1.01);
  }

  @media only screen and (max-width: 576px) {
    width: ${COLUMN_WIDTH}px;
  }

  @media only screen and (min-width: 577px) {
    width: ${(props) => (props.largerow ? SMALL_COLUMN_WIDTH : COLUMN_WIDTH)}px;
  }

  @media only screen and (min-width: 1400px) {
    width: ${(props) => (props.largerow ? LARGE_COLUMN_WIDTH : COLUMN_WIDTH)}px;
  }

  @media only screen and (min-width: 1700px) {
    width: ${(props) =>
    props.largerow ? XLARGE_COLUMN_WIDTH : COLUMN_WIDTH}px;
  }
`;

const AppointmentCardInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const TooltipCardInner = styled.div`
  width: 100%;
  height: auto;
  color: black;
  box-sizing: border-box;
`;

const TooltipCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;

  font-weight: 500;

  svg {
    height: 16px;
    margin-right: 4px;
  }
  span {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
  }
`;

const AppointmentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;

  svg {
    height: 16px;
    margin-right: 4px;
  }
  span {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
  }
`;

const DateNowIndicator = styled.div`
  width: 100%;
  height: 2px;
  background: #f7c0ba;
  position: absolute;
  z-index: 300;
`;

const DetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
`;

const DetailIcon = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  i {
    font-size: 21px;
  }
`;

const DetailInner = styled.div`
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;

  h4 {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
  }
  span {
    color: #202020;
    font-size: 14px;
  }
`;

const DetailBoxInner = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7e508e;
    margin-right: 8px;
  }
`;

const DetailBoxInnerCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  background: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
    color: #404040;
  }
`;

const AlignContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const TableButtonLeft = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;
  left: 16.6%;
  top: 51.28%;
  bottom: 29.17%;
  z-index: 99;
  background-color: rgba(49, 137, 236, 0.25);

  box-shadow: 0px 3px 6px rgba(133, 133, 233, 0.369);

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
const TableButtonRight = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;
  left: 92.83%;
  right: 15.04%;
  top: 51.28%;
  bottom: 29.17%;
  background-color: rgba(49, 137, 236, 0.25);

  z-index: 99;

  box-shadow: 0px 3px 6px rgba(133, 133, 233, 0.369);

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
const IconWrapper = styled.div`
  width: 90px;
  background-color: ${process.env.APP_NAME === "salon"
    ? "#3189ec"
    : process.env.APP_NAME === "en"
      ? "#ffa000"
      : "#ed1e79"};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 6px;
  gap: 2px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    opacity: 0.9; /* Hover effect: Change to desired color */
  }
`;
const DetailCard = (props) => (
  <DetailBox>
    <DetailIcon className={`bg-${props.color}-opacity fc-${props.color}`}>
      <i className="material-icons">{props.icon}</i>
    </DetailIcon>
    <DetailInner>
      <h4>{props.label}</h4>
      <span>{props.value}</span>
    </DetailInner>
    <DetailBoxInner>
      <span>{props.sabValue}</span>
    </DetailBoxInner>
    {props.iconValue && (
      <DetailBoxInnerCon>
        <i
          className="material-icons"
          style={{
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={props.click ? props.click : () => { }}
        >
          {props.iconValue}
        </i>
      </DetailBoxInnerCon>
    )}
  </DetailBox>
);

export default AppointmentsTableIndex;
