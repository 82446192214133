/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Button from "../../../theme/Button";

import Input from "../../../theme/CustomMUI/Input";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";

import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";

import moment from "moment";

import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import { getCities } from "../../../functions/CityDistrict";
import getDayIndex from "../../../functions/GetDayIndex";
import { toast } from "react-toastify";
import {
  Grid,
  Container,
  CircularProgress,
  ButtonBase,
  TextField,
} from "@material-ui/core";
import { Column } from "../../../theme/Grid";
import Pagination from "@material-ui/lab/Pagination";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  StorefrontOutlined,
  AccountCircleOutlined,
  Clear,
  Info,
} from "@material-ui/icons";
import { Rating } from "@material-ui/lab";

import TranslateView from "../../../components/Scaffold/TranslateView";

import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import ReCaptcha from "../../../components/ReCaptcha";
import AreaCode from "./AreaCode";
import { useParams } from "react-router-dom/cjs/react-router-dom";


const BusinessWebsite = () => {
  const context = useContext(AppContext);
  const params = useParams();

  const [TAB_TYPE, setTAB_TYPE] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [instagram, setInstagram] = useState({
    loaded: false,
    data: []
  });
  const [comments, setComments] = useState({
    records: []
  });

  const [business, setBusiness] = useState({
    id: null,
    name: null,
    mail: null,
    phone: null,
    province: null,
    district: null,
    address: null,
    map: null,
    instagram: null,
    facebook: null,
    shift: {
      start_hour: "00:00",
      end_hour: "23:59",
      slice: 20,
    },
    staffs: [],
    services: [],
    show_amount_on_appointment: null,
    show_customer_comments_at_url: null
  });

  const [appointment, setAppointment] = useState({
    customer_name: null,
    customer_surname: null,
    customer_phone: null,
    staff_id: null,
    __service: null,
    service_id: null,
    date: moment(new Date()).format("DD/MM/YYYY"),
    time: null,
    note: ""
  });
  const [appointment_check_modal, setAppointmentCheckModal] = useState(false);

  const [available_data, setAvailableData] = useState({});
  const [approvement_code_model, setApprovementCodeModel] = useState(false);
  const [approvement_code, setApprovementCode] = useState(null);
  const [current_hour, setCurrentHour] = useState("");

  const [code, setCode] = useState("");
  const [is_code_wrong, setIsCodeWrong] = useState(false);

  const [showMoreList, setShowMoreList] = useState([]);
  const [select_language, setSelectLanguage] = useState(false);
  const [lang, setLang] = useState(context.state.default_locale);
  const [reCaptchaCode, setRecCaptchaCode] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [getCodeCheck, setGetCodeCheck] = useState(false);

  const [hours_expanded, setHoursExpanded] = useState(false);
  const [selected_service, setSelectedService] = useState(null);
  const [selected_employee, setSelectedEmployee] = useState(null);

  const translateServiceDescription = async (text, targetLanguage) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    if (text === "" || text === null) {
      return text;
    } else {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            q: text,
            target: targetLanguage,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Çeviri isteği başarısız oldu");
      }

      const data = await response.json();
      return data.data.translations[0].translatedText;
    }
  };

  const getCode = () => {
    Axios.post(
      `${context.api_endpoint.slice(
        0,
        34
      )}/api/platform/appointment/request`,
      {
        company_id: business.id,
        phone: appointment.customer_phone.slice(
          appointment.dialCode.length
        ),
        dialCode: "+" + appointment.dialCode,
      }
    )
      .then((response) => {
        if (response.data.status === 201) {
          toast.success(response.data.data);
          setGetCodeCheck(true);
        }
      })
      .catch((err) => {
        toast.error("Somenting went wrong!");
        console.log(err);
      });
  };

  const getBusiness = async () => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/request/index/${params.id}`
      );
      if (response.status === 201) {
        const { data } = response;
        let translatedServices = await Promise.all(
          data.data.services.map(async (service) => {
            const translatedServiceName =
              await translateServiceDescription(
                service.name,
                lang
              );
            const translatedServiceDescription =
              await translateServiceDescription(
                service.description,
                lang
              );
            return {
              ...service,
              name: translatedServiceName,
              description: translatedServiceDescription,
              staffs: [
                ...service.staffs
                  .map((staff) =>
                    staff.staff !== null ? { ...staff.staff } : undefined
                  )
                  .filter((item) => item !== undefined),
              ],
            };
          })
        );

        setLoaded(false);
        setBusiness({
          ...business,
          ...data.data,
          instagram:
            data.data.instagram !== "" && data.data.instagram !== null
              ? data.data.instagram
              : context.app.INSTAGRAM,
          show_amount_on_appointment:
            data.data.company_settings.show_amount_platform === 1
              ? true
              : false,
          province: data.data.province
            ? getCities().filter((city) => city.id === data.data.province)[0]
              .name
            : false,
          shift: data.data.hours,
          staffs: data.data.staffs ? [...data.data.staffs] : [],
          services: translatedServices
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInstagramPosts = () => {
    fetch(
      `https://www.instagram.com/${business.instagram ? business.instagram : context.app.INSTAGRAM
      }/?__a=1`
    )
      .then((res) => res.json())
      .then((response) => {
        const { edge_owner_to_timeline_media } = response.graphql.user;

        // edge_owner_to_timeline_media.edges.slice(0,6).map((post, index) => {
        setInstagram({
          loaded: true,
          data: [...edge_owner_to_timeline_media.edges]
        });
      });
  };

  useEffect(() => {
    getBusiness()
  }, []);

  /**
   * İstekten cevap gelene kadar olan süreçte ilgili alanda loader gösteren builder.
   */
  const LoaderBuilder = () => {
    return (
      <LoaderContainer>
        <CircularProgress variant="indeterminate" color="primary" />
      </LoaderContainer>
    );
  };

  /**
   * İşletme bilgisi altında tab navigator oluşturan ve
   * ilgili tab'in component'ini sayfaya dahil eden builderfonksiyonudur..
   */
  const TabNavigatorBuilder = () => {
    const Tab = NavigatorSingleTabBuilder;

    // Components
    const AppointmentCard = AppointmentCardBuilder;
    const ServicesCard = ServicesCardBuilder;
    const StaffsCard = StaffsCardBuilder;
    const CommentsCard = CommentsCardBuilder;

    // Navigator Settings
    const TabNavigator = [
      {
        title: context.t(`businessURL.appointment.tab`),
        component: <AppointmentCard />,
      },
      {
        title: context.t(`businessURL.services.tab`),
        component: <ServicesCard />,
      },
      {
        title: context.t(`businessURL.staffs.tab`),
        component: <StaffsCard />,
      },
      {
        title: context.t(`businessURL.comments.tab`),
        component: <CommentsCard />,
      },
    ];

    return (
      <Column>
        <TNavigator className="mt-3">
          {TabNavigator.filter((_tab) => _tab !== undefined).map(
            (_tab, index) => (
              <Tab
                key={index}
                title={_tab.title}
                control={TAB_TYPE === index ? "active" : ""}
                onClick={() => setTAB_TYPE(index)}
              />
            )
          )}
          <ResponsiveDiv>
            <LanguageSelective
              id="languageBox"
              onClick={() => {
                setSelectLanguage(!select_language);
              }}
            >
              <Flag
                src={require(`../../../assets/images/flags/${lang}.svg`)}
              />
              {lang === "tr" && "Türkçe"}
              {lang === "en" && "English"}
              {lang === "ru" && "Russia"}
              {lang === "ar" && "Arabic"}
            </LanguageSelective>
            <TranslateView
              state={select_language}
              closeHandler={() => {
                setSelectLanguage(false);
              }}
              stopPropagation={(e) => e.stopPropagation()}
            />
          </ResponsiveDiv>
        </TNavigator>

        {TabNavigator[TAB_TYPE].component}
      </Column>
    );
  };

  /**
   * _TabNavigatorBuilder içerisinde kullanılacak olan tekli tab buttonun builder fonksiyonudur.
   */
  const NavigatorSingleTabBuilder = ({ title, control, onClick }) => {
    return (
      <TNavigatorTab>
        <ButtonBase className={control} onClick={onClick}>
          {title}
        </ButtonBase>
      </TNavigatorTab>
    );
  };

  /**
   * İşletme adı, il/ilçe ve Hemen Ara buttonlarının bulunduğu kart görünümünün builder fonksiyonudur.
   */
  const BusinessHeaderBuilder = () => {
    return (
      <Card>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <BusinessHeading>
              <h1 className="limited-line-2">{business.name}</h1>
              {business.province && (
                <span>
                  {business.province} / {business.district}
                </span>
              )}
            </BusinessHeading>
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: "flex" }}>
            <Button
              icon="call"
              title={context.t(`businessURL.callButtonTitle`, {
                phone: business.phone,
              })}
              textColor="green"
              backgroundColor="green-opacity"
              size="md"
              fullWidth
              onClick={() => window.open(`tel:0${business.phone}`)}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  /**
   * İşletme fotoğrafları -varsa- galeri alanını oluşturan alandır.
   */
  const GalleryCardBuilder = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const images =
      business.gallery && business.gallery.length > 0
        ? business.gallery.map(
          ({ photo }, index) =>
            `${context.api_endpoint.replace("api/v1", "")}${params.id}/${photo.path
            }`
        )
        : [];

    return business.gallery && business.gallery.length > 0 ? (
      <Card className="mt-3">
        <LeadingPhoto
          src={`${context.api_endpoint.replace("api/v1", "")}${params.id
            }/${business.gallery[0].photo.path}`}
          onClick={async () => {
            await setPhotoIndex(0);
            await setModalOpen(true);
          }}
        />
        <Grid container spacing={2}>
          {business.gallery.length <= 4
            ? business.gallery.map(({ photo }, index) => (
              <Grid item key={index} xs={3} sm={3}>
                <SmallPhoto
                  src={`${context.api_endpoint.replace("api/v1", "")}${params.id
                    }/${photo.path}`}
                  onClick={async () => {
                    await setPhotoIndex(index);
                    await setModalOpen(true);
                  }}
                />
              </Grid>
            ))
            : business.gallery.slice(0, 4).map(({ photo }, index) => (
              <Grid item key={index} xs={3} sm={3}>
                <SmallPhoto
                  src={`${context.api_endpoint.replace("api/v1", "")}${params.id
                    }/${photo.path}`}
                  onClick={async () => {
                    await setPhotoIndex(index);
                    await setModalOpen(true);
                  }}
                />
              </Grid>
            ))}
        </Grid>

        {modalOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setModalOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Card>
    ) : null;
  };

  /**
   * Randevu talebi oluşturma görünümü ve attığı isteklerin bulunduğu builder fonksiyonudur.
   */
  const AppointmentCardBuilder = () => {
    const getUnavailableHours = () => {
      const date_arr = appointment.date.split("/");
      const date = new Date(
        `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T00:00:00`
      );

      Axios.get(
        `${context.api_endpoint}/request/${params.id}/staff/active`,
        {
          params: {
            staff_id: appointment.staff_id,
            day_index: getDayIndex(moment(date).format("YYYY-MM-DD")),
            date: moment(date).format("YYYY-MM-DD"),
            process_time: selected_service.process_time,
          },
        }
      ).then(({ data }) => {
        if (data.data.free_hours === null) {
          setHoursExpanded(true);
          setAvailableData({ ...data.data, free_hours: [] });
          setCurrentHour(data.data.current_date);
        } else {
          setHoursExpanded(true);
          setAvailableData(data.data);
          setCurrentHour(data.data.current_date);
        }
      });
    };
    const phoneNumberHandler = (value, data, event, formattedValue) => {
      setAppointment({
        ...appointment,
        customer_phone: value,
        dialCode: data.dialCode
      });
    };
    React.useEffect(() => {
      if (getCodeCheck) {
        setTimeout(() => {
          makeAppointmentRequest();
        }, 500);
      }
    }, [getCodeCheck]);
    const makeAppointmentRequest = () => {
      const date_arr = appointment.date.split("/");
      const time_arr = appointment.time.split(":");

      Axios.post(
        `${context.api_endpoint.slice(
          0,
          34
        )}/api/platform/appointment/request/approve`,
        {
          dialCode: appointment.dialCode ? "+" + appointment.dialCode : "",
          company_id: business.id,
          customer_name: appointment.customer_name,
          customer_phone: appointment.customer_phone
            ? appointment.customer_phone.slice(appointment.dialCode.length)
            : "",
          customer_surname: appointment.customer_surname,
          date: appointment.time,
          email: "",
          note: appointment.note,
          zone: 0,
          service_id: appointment.service_id,
          staff_id: appointment.staff_id,
        }
      )
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              context.t(`businessURL.appointment.insertToast`)
            );
            setIsCodeWrong(false);
            setAppointmentCheckModal(false);
            setHoursExpanded(false);
            setAppointment({
              customer_name: null,
              customer_surname: null,
              customer_phone: null,
              company_id: params.id,
              staff_id: null,
              service_id: null,
              date: moment(new Date()).format("DD/MM/YYYY"),
              time: null
            });
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.status);
          setIsCodeWrong(true);
          toast.warning(context.t(`businessURL.wrong_code`));
        });
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{context.t(`businessURL.appointment.title`)}</h2>
        </SectionHeading>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={context.t(`businessURL.appointment.nameInputLabel`)}
              value={
                appointment.customer_name !== null
                  ? appointment.customer_name
                  : ""
              }
              onChange={(e) => setAppointment({
                ...appointment,
                customer_name: e.target.value
              })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={context.t(
                `businessURL.appointment.surnameInputLabel`
              )}
              value={
                appointment.customer_surname !== null
                  ? appointment.customer_surname
                  : ""
              }
              onChange={(e) =>
                setAppointment({
                  ...appointment,
                  customer_surname: e.target.value
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PhoneInput
              inputStyle={{
                width: "100%", // Set a default width
              }}
              country={
                AreaCode?.find(
                  (el) => el?.areaCode === context.state.dialCode
                )?.country ?? "tr"
              }
              countryCodeEditable={false}
              value={
                appointment.customer_phone !== null
                  ? appointment.customer_phone
                  : ""
              }
              onChange={phoneNumberHandler}
              specialLabel={context.t(
                `businessURL.appointment.phoneInputLabel`
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <AutocompleteInput
              label={context.t(
                `businessURL.appointment.serviceInputLabel`
              )}
              placeholder={context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              helperText={context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              selected={selected_service || null}
              labelKey="name"
              valueKey="id"
              returnFullObject
              options={[
                ...business.services.map((item) => ({
                  ...item,
                  name: `${item.name} ${business.show_amount_on_appointment
                      ? `(${item.amount.toFixed(2)}${context.state.currency
                      })`
                      : ""
                    }`,
                })),
              ]}
              onRemove={() => {
                setHoursExpanded(false);
                setSelectedService(null);
                setAppointment({
                  ...appointment,
                  __service: null,
                  service_id: null,
                  staff_id: null
                });
              }}
              selectedHandler={(service) => {
                setHoursExpanded(false);
                setSelectedService(service);
                setAppointment({
                  ...appointment,
                  __service: service,
                  service_id: service.id,
                  staff_id: null
                });
              }}
              asyncDataService={(keyword) => {
                const data = [
                  ...business.services.map((item) => ({
                    ...item,
                    name: `${item.name} ${business.show_amount_on_appointment
                        ? `(${item.amount.toFixed(2)}${context.state.currency
                        })`
                        : ""
                      }`,
                  })),
                ];

                return keyword
                  ? data.filter((item) =>
                    item.name
                      .toLocaleLowerCase()
                      .includes(keyword.toLocaleLowerCase())
                  )
                  : data;
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Select
              required
              label={context.t(`businessURL.appointment.staffInputLabel`)}
              helperText={context.t(
                `businessURL.appointment.staffInputHint`
              )}
              items={selected_service ? selected_service.staffs : []}
              selected={
                appointment.staff_id !== null ? appointment.staff_id : ""
              }
              labelKey="full_name"
              valueKey="id"
              returnFullObject
              handler={(selected_employee) => {
                setHoursExpanded(false);
                setSelectedEmployee(selected_employee);
                setAppointment({
                  ...appointment,
                  staff_id: selected_employee.id,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <DatePickerInput
              required
              label={context.t(`businessURL.appointment.dateInputLabel`)}
              minDate={new Date()}
              // maxDate={new Date().setDate(new Date().getDate() + 30)}
              helperText={context.t(
                `businessURL.appointment.dateInputHint`
              )}
              onChange={(date) => {
                setHoursExpanded(false);
                setAppointment({
                  ...appointment,
                  date: moment(date).format("DD/MM/YYYY"),
                });
              }}
            />
          </Grid>
        </Grid>

        <Button
          disabled={
            business.shift.slice === "0" ||
            appointment.customer_name === null ||
            appointment.customer_surname === null ||
            appointment.customer_phone === null ||
            appointment.staff_id === null ||
            appointment.staff_id === null ||
            appointment.service_id === null
          }
          icon="search"
          title={context.t(
            `businessURL.appointment.availableHoursButtonTitle`
          )}
          backgroundColor="primary"
          textColor="white"
          fullWidth={true}
          onClick={() => getUnavailableHours()}
        />

        {hours_expanded === true && reCaptchaCode !== null && (
          <Grid container spacing={2} className="mt-4">
            {available_data.free_hours
              .filter((item) => moment(item) >= moment(current_hour))
              .map((item, index) => {
                // const time_arr = item.split("T")[0];
                const date_arr = `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                  }`;

                return (
                  <Grid date_arr key={index} xs={6} sm={6} md={3} lg={2}>
                    <Button
                      title={date_arr}
                      fullWidth={true}
                      textColor={"green"}
                      onClick={() => {
                        setConfirmModal(true);
                        setAppointment({
                          ...appointment,
                          time: item
                        });
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
        {hours_expanded === true &&
          reCaptchaCode !== null &&
          available_data.free_hours?.filter(
            (item) => moment(item) >= moment(current_hour)
          ).length === 0 && (
            <Grid>{context.t(`businessURL.appointment.appointmentHourErrorTitle`)}</Grid>
          )}

        {hours_expanded === true && reCaptchaCode === null && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ReCaptcha
              reCaptchaCodeHandler={(code) => {
                setRecCaptchaCode(code);
              }}
            />
          </div>
        )}

        <AlertDialog
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>{context.t(`businessURL.appointment.appointmentAccept`)}</h3>
              <Clear />
            </div>
          }
          open={approvement_code_model}
          closeHandler={() => setApprovementCodeModel(false)}
        >
          <Alert severity="warning">
            <b>
              {context.t(`businessURL.appointment.appointmentAcceptCodeText`)}
            </b>
          </Alert>
          <form>
            <TextField
              className="mt-2 mb-2"
              fullWidth
              type="tel"
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontSize: 28,
                  fontWeight: "400",
                },
                maxLength: 4,
              }}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            {is_code_wrong && (
              <div
                className="mt-1 mb-1"
                style={{ fontSize: "22", fontWeight: "500", color: "red" }}
              >
                {context.t(`businessURL.appointment.wrongCodeErrorText`)}
              </div>
            )}
            <Button
              disabled={code.length < 4}
              icon={"check"}
              title={context.t(`["appointments/online"].approveButtonTitle`)}
              fullWidth
              backgroundColor={"primary-opacity"}
              onClick={() => {
                //makeAppointmentRequest();
              }}
            />
            <div
              className="mt-1 mb-3"
              style={{
                display: "flex",
                textAlign: "center",
                cursor: "pointer",
                color: "orange",
                marginLeft: "auto",
                width: "fit-content",
              }}
              onClick={() => getCode()}
            >
              <Info />
              {context.t(`businessURL.appointment.sendAgainText`)}
            </div>
          </form>
        </AlertDialog>
        {/* Confirm appointment */}
        <ModalDialog
          open={confirmModal}
          closeHandler={() => setConfirmModal(false)}
          title="Do you confirm this appointment ?"
          buttons={[
            {
              title: context.t(
                `component.CADialog.delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => getCode(), // makeAppointmentRequest(),
            },
            {
              title: context.t(
                `component.CADialog.delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            {/*    <b>{context.t(`component.CADialog.confirmCustomerTitle`)}</b>
            {context.t(`component.CADialog.confirmTitle1`)}{" "}
            <b>{context.t(`component.CADialog.confirmTitle2`)}</b>{" "}
            {context.t(`component.CADialog.confirmTitle3`)}  */}
            <b>
              If you confirm this appointment, a notification will be sent to
              the business where you made the appointment.
            </b>
          </Alert>
        </ModalDialog>
      </Card>
    );
  };

  /**
   * İşletmenin sunduğu tüm hizmetlerin listelendiği builder fonksiyonudur.
   */
  const ServicesCardBuilder = () => {
    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{context.t(`businessURL.services.title`)}</h2>
        </SectionHeading>

        {[...business.services].map((service, index) => (
          <SingleRowItem key={index}>
            <Grid container>
              <Grid item xs={12} sm={8}>
                <span id="heading">{service.name}</span>
                <span id="subtext">
                  <p>
                    {context.t(`businessURL.services.processTime`, {
                      time: service.process_time,
                    })}
                  </p>
                  {business.show_amount_on_appointment && (
                    <p>
                      {service.amount.toFixed(2)}
                      {context.state.currency}
                    </p>
                  )}
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}
      </Card>
    );
  };

  /**
   * İşletmenin sunduğu tüm hizmetlerin listelendiği builder fonksiyonudur.
   */
  const StaffsCardBuilder = () => {
    React.useEffect(() => getStaffs(), []);

    const getStaffs = () => {
      Axios.get(
        `${context.api_endpoint}/request/index/${params.id}/staffs`
      ).then(({ data }) => setBusiness({ ...business, staffs: [...data.data] })
      );
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{context.t(`businessURL.staffs.title`)}</h2>
        </SectionHeading>

        {[...business.staffs].map((staff, index) => (
          <SingleRowItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm="auto">
                <StaffPhoto
                  src={
                    staff.detail.profile_photo
                      ? `${context.api_endpoint.replace("api/v1", "")}${params.id
                      }/${staff.detail.profile_photo}`
                      : require("../../../assets/images/profile_photo.svg")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <span id="heading" className="limited-line-1">
                  {staff.full_name}
                </span>
                <span id="subtext">
                  <p>{staff.detail.job_title}</p>
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}
      </Card>
    );
  };

  /**
   * İşletmeye gelen yorumların listelendiği builder fonksiyonudur.
   */
  const CommentsCardBuilder = () => {
    React.useEffect(() => getComments(), []);

    const getComments = (page = 1) => {
      Axios.get(
        `${context.api_endpoint}/request/index/${params.id}/comments`,
        { params: { page } }
      ).then(({ data }) => setComments({ ...data.data }));
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{context.t(`businessURL.comments.title`)}</h2>
        </SectionHeading>

        {[...comments.records].map((comment, index) => (
          <SingleRowItem key={index}>
            <Grid container>
              <Grid item xs={12} sm={11}>
                <span
                  id="heading"
                  className={
                    showMoreList.includes(index) ? "" : "limited-line-2"
                  }
                >
                  {comment.description !== "" && comment.description !== null
                    ? comment.description
                    : "-"}
                </span>
                <span
                  style={{ cursor: "pointer", display: "block" }}
                  onClick={() => {
                    if (showMoreList.includes(index)) {
                      setShowMoreList(showMoreList.filter(
                        (item) => item !== index
                      ));
                    } else {
                      setShowMoreList([...showMoreList, index])
                    }
                  }}
                >
                  {showMoreList.includes(index)
                    ? context.t(`businessURL.showLess`)
                    : context.t(`businessURL.showMore`)}
                </span>
                <span id="subtext">
                  <p>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StorefrontOutlined style={{ height: 20 }} />
                      <Rating
                        readOnly
                        size="small"
                        style={{ display: "flex" }}
                        precision={0.5}
                        max={5}
                        value={comment.company_star.toFixed(2)}
                      />
                      <span style={{ marginLeft: "6px" }}>
                        {comment.company_star.toFixed(2)}
                      </span>
                    </span>
                  </p>

                  <p>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountCircleOutlined style={{ height: 20 }} />
                      <Rating
                        readOnly
                        size="small"
                        style={{ display: "flex" }}
                        precision={0.5}
                        max={5}
                        value={comment.staff_star.toFixed(2)}
                      />
                      <span style={{ marginLeft: "6px" }}>
                        {comment.staff_star.toFixed(2)}
                      </span>
                    </span>
                  </p>
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}

        <PaginationContainer>
          <Pagination
            count={comments.total_page}
            onChange={(_, value) =>
              comments.page !== value && getComments(value)
            }
          />
        </PaginationContainer>
      </Card>
    );
  };

  /**
   * Sidebar alanda işletmenin varsa Instagram -yoksa @salonrandevu- hesabının son fotoğraflarının listeletildiği
   * builder fonksiyonudur.
   */
  const InstagramCardBuilder = () => {
    return (
      <Card>
        <SectionHeading>
          <h2>{context.t(`businessURL.instagramHeading`)}</h2>
          <SocialProfileLink
            href={`https://instagram.com/${business.instagram}`}
            target="_blank"
          >
            <span>
              {context.t(`businessURL.instagramText`, {
                username: business.instagram ?? "Enrandevu",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>

        {/* <Grid container spacing={2}>
          {
            instagram.loaded
              ? instagram.data.map((post, index) => {
                const { shortcode, thumbnail_resources } = post.node;
                return (
                  <Grid key={index} item xs={12} sm={4}>
                    <PhotoLink href={`https://www.instagram.com/p/${shortcode}`} target="_blank">
                      <img src={thumbnail_resources[1].src} alt={business.name} />
                    </PhotoLink>
                  </Grid>
                )
              })
              : [...new Array(12)].map((_, index) =>
                <Grid key={index} item xs={12} sm={4}>
                  <InstagramPhotoSkeleton className="skeleton" />
                </Grid>
              )
          }
        </Grid> */}
      </Card>
    );
  };

  const FacebookCardBuilder = () => {
    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>{context.t(`businessURL.facebookHeading`)}</h2>
          <SocialProfileLink
            href={`https://facebook.com/${business.facebook ? business.facebook : "Enrandevu"
              }`}
            target="_blank"
          >
            <span>
              {context.t(`businessURL.facebookText`, {
                username: business.facebook ? business.facebook : "Enrandevu",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>
        <iframe
          style={{ width: "100%", minHeight: 181, border: 0 }}
          src={`https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/${business.facebook ?? "salonrandevucom"
            }/&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media`}
        ></iframe>
      </Card>
    );
  };

  /**
   * Sidebar alanda işletmenin varsa Instagram -yoksa @salonrandevu- hesabının son fotoğraflarının listeletildiği
   * builder fonksiyonudur.
   */
  const AboutCardBuilder = () => {
    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>{context.t(`businessURL.aboutHeading`)}</h2>
        </SectionHeading>
        <Grid container spacing={2}>
          {business.map !== null && business.map !== "" && (
            <Grid item xs={12} sm={12}>
              <iframe
                title={Date.now()}
                src={`https://maps.google.com/maps?q=@${business.map}&zoom=14&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                width="100%"
                height="240"
                frameBorder={0}
                style={{ border: 0 }}
                aria-hidden="false"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <div className="mb-1">
              <b>{context.t(`businessURL.phoneText`)}:</b> 0
              {business.phone ?? "-"}
            </div>
            <div className="mb-1">
              <b>{context.t(`businessURL.mailText`)}:</b>{" "}
              {business.mail ?? "-"}
            </div>
            <div className="mb-1">
              <b>{context.t(`businessURL.addressText`)}:</b>{" "}
              {business.address ?? "-"}
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  };


  const Loader = LoaderBuilder;
  const BusinessHeader = BusinessHeaderBuilder;
  const GalleryCard = GalleryCardBuilder;
  const TabNavigator = TabNavigatorBuilder;

  const InstagramCard = InstagramCardBuilder;
  const FacebookCard = FacebookCardBuilder;
  const AboutCard = AboutCardBuilder;

  return (
    <SiteContainer>
      <Helmet>
        <title>
          {context.app.NAME}{" "}
          {business.name !== null ? `- ${business.name}` : ""}
        </title>
      </Helmet>

      {loaded ? (
        <div className="animated fadeIn">
          <Container>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <BusinessHeader />
                {/* <GalleryCard /> */}
                <TabNavigator />

                {/*<LanguagesContainer className="mt-3">
                  <LanguageButton heading="Türkçe" lang="tr" />
                  <LanguageButton heading="English" lang="en" />
                </LanguagesContainer>*/}
              </Grid>

              <Grid item xs={12} md={4}>
                <InstagramCard />
                <FacebookCard />
                <AboutCard />
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <Loader />
      )}
    </SiteContainer>
  );

}

export default BusinessWebsite;

const SiteContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0;
  box-sizing: border-box;
  background: #fcfcfc;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TNavigator = styled.ul`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  background: #fff;
  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column; /* Change to column layout on smaller screens */
    border-radius: 0; /* Remove border radius on smaller screens */
    /* Add any additional styles for smaller screens */
  }
`;
const ResponsiveDiv = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; /* Make sure it doesn't exceed the screen width */
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    /* Adjust styles for smaller screens */
    width: 100%; /* Set width to 100% on smaller screens */
    max-width: none; /* Remove max-width on smaller screens if needed */
  }
`;
const TNavigatorTab = styled.li`
  width: auto;
  flex: none;
  text-align: center;

  button {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 16px;
    line-height: 26px;
    color: #303030;

    &.active {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }
`;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02);
`;

const BusinessHeading = styled.div`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 22px;
    line-height: 24px;

    & + span {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: #aaa;
      margin-top: 4px;
    }
  }
`;

const LeadingPhoto = styled.img`
  width: 100%;
  height: 300px;
  object-fit: fill;
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const SmallPhoto = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionHeading = styled.h2`
  margin: 0 0 16px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;

const InstagramPhotoSkeleton = styled.div`
  width: 100%;
  height: 105px;
  border-radius: 10px;
`;

const PhotoLink = styled.a`
  img {
    width: 100%;
    height: 105px;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const SocialProfileLink = styled.a`
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  color: #7713b5;
  text-decoration: none;

  i {
    font-size: 15px;
  }
  b {
    font-weight: 600;
  }
`;

const SingleRowItem = styled.div`
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  color: #303030;
  border-bottom: 1px solid #efefef;

  &:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &:nth-of-type(1) {
    padding-top: 0 !important;
  }

  span#heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }

  span#subtext {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    margin-top: 0px;

    p {
      font-size: 13px;
      line-height: 1.614;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-right: 16px;

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
`;

const StaffPhoto = styled.img`
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 10px;
  background: #fafafa;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LanguageSelective = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`;

const Flag = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 8px;

  & + p {
    font-size: 12px !important;
    color: #303030;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;
